const blue = {
  normal: '#1e88e5',
  light: '#64b5f6',
  mediumLight: '#cce7ff',
  lighter: '#e3f2fd',
  lightest: '#eef6fb',
  dark: '#1976d2',
  darker: '#1565c0',
}

const orange = {
  normal: '#ff8c00',
  lighter: '#ffd8a8',
  darker: '#e07b00',
}

const accentCol = blue

const colors = {
  white: '#fff',
  black: '#000',
  blackish: '#2b2c31',
  grey: '#808183',
  greyMedium: '#a5a5a7',
  greyMediumLight: '#dfe0e1',
  greyLight: '#e6e9eb',
  greyLighter: '#f1f2f5',
  greyXLighter: '#f5f6f7',
  greyLightest: '#f9fafc',
  greyDark: '#68696C',
  greyDarker: '#47484a',
  accent: accentCol.normal,
  accentLight: accentCol.light,
  accentMediumLight: accentCol.mediumLight,
  accentLighter: accentCol.lighter,
  accentLightest: accentCol.lightest,
  accentDark: accentCol.dark,
  accentDarker: accentCol.darker,
  warning: orange.normal,
  warningLighter: orange.lighter,
  warningDarker: orange.darker,
}

export default colors
