import '@fontsource/bonbon'
import '@fontsource/boogaloo'
import '@fontsource/bowlby-one'
import '@fontsource/dancing-script'
import '@fontsource/fascinate-inline'
import '@fontsource/hachi-maru-pop'
import '@fontsource/oswald'
import '@fontsource/playfair-display'
import '@fontsource/ribeye-marrow'
import '@fontsource/roboto'
import '@fontsource/sacramento'
import '@fontsource/sedgwick-ave'
import '@fontsource/special-elite'


export const FontConfigs = {
  bonbon: ['Bonbon', '@fontsource/bonbon'],
  boogaloo: ['Boogaloo', '@fontsource/boogaloo'],
  bowlbyOne: ['Bowlby One', '@fontsource/bowlby-one'],
  courierNew: ['Courier New'],
  dancingScript: ['Dancing Script', '@fontsource/dancing-script'],
  fascinate: ['Fascinate Inline', '@fontsource/fascinate-inline'],
  hachiMaruPop: ['Hachi Maru Pop', '@fontsource/hachi-maru-pop'],
  oswald: ['Oswald', '@fontsource/oswald'],
  playfairDisplay: ['Playfair Display', '@fontsource/playfair-display'],
  ribeyeMarrow: ['Ribeye Marrow', '@fontsource/ribeye-marrow'],
  roboto: ['Roboto', '@fontsource/roboto'],
  sacramento: ['Sacramento', '@fontsource/sacramento'],
  sedgwickAve: ['Sedgwick Ave', '@fontsource/sedgwick-ave'],
  specialElite: ['Special Elite', '@fontsource/special-elite'],
}


export const FontImports = Object.values(FontConfigs).reduce((accum, value) => {
  if (value[1]) {
    accum.push(value[1])
  }
  return accum
}, [])


export const Fonts = Object.entries(FontConfigs).reduce((accum, [key, value]) => {
  return {
    ...accum,
    [key]: value[0],
  }
}, {})
