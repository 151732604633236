import { Route, Routes, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

import './_consts/fonts'
import ReadView from './ReadView'
import { AppProvider } from './AppProvider'
import { ModalProvider } from './ModalProvider'
import EditView from './EditView'
import About from './About'
import { AnimationProvider } from './AnimationProvider'


const useAppDims = () => {
  const [dims, setDims] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setDims({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return dims
}


const AppInner = () => {
  const { linkId, mode } = useParams()
  const appDims = useAppDims()
  const isWideMedia = appDims?.width > 700
  const isEditMode = !linkId || mode === 'e'

  return (
    <AppProvider
      appDims={appDims}
      isEditMode={isEditMode}
      isWideMedia={isWideMedia}
    >
      <AnimationProvider>
        <ModalProvider>
          <Container>
            {isEditMode ? <EditView /> : <ReadView />}
          </Container>
        </ModalProvider>
      </AnimationProvider>
    </AppProvider>
  )
}

const App = () => {
  return (
    <Routes>
      <Route
        path='about'
        element={<About />}
      />
      <Route
        path=':linkId/:mode'
        element={<AppInner />}
      />
      <Route
        path=':linkId'
        element={<AppInner />}
      />
      <Route
        path=''
        element={<AppInner />}
      />
    </Routes>
  )
}

export default App

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
