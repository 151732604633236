import { useContext } from 'react'

import { AppContext } from './AppProvider'
import InputTextarea from './_components/InputTextarea'
import colors from './_consts/colors'


const EditText = () => {
  const { config, updateConfig, isDarkBg } = useContext(AppContext)

  return (
    <InputTextarea
      rows={5}
      maxRows={10}
      onChange={(e) => updateConfig({ text: e.target.value })}
      maxLength={1000}
      placeholder='Write something'
      value={config.text}
      color={isDarkBg ? colors.white : colors.blackish}
      fontSize={18}
      padding='1rem'
    />
  )
}

export default EditText
