import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { flatten } from 'lodash'
import { MdCheck } from 'react-icons/md'

import Dropdown from './Dropdown'
import Text from './Text'
import colors from '../_consts/colors'
import { AppContext } from '../AppProvider'
import useScrollToItem from '../_hooks/useScrollToItem'
import useActiveListItem from '../_hooks/useActiveListItem'
import useEventListener from '../_hooks/useEventListener'


const DropdownList = ({
  autoHighlight = false,
  children,
  isOpen = false,
  items = [],
  minWidth,
  maxHeight = '15rem',
  onBlur = () => {},
  onSelect,
  rightAlign,
  scrollToItem,
  ...restProps
}) => {
  const triggerRef = useRef()
  const isGrouped = Array.isArray(items[0])
  const flatItems = flatten(items, true)

  const {
    activeItem,
    activeItemIndex,
    canScrollToActive,
    goToNextItem,
    goToPrevItem,
    goToItem,
    hoverItem,
  } = useActiveListItem(flatItems, autoHighlight)

  const { containerRef, itemsRef } = useScrollToItem(flatItems, activeItemIndex, canScrollToActive)

  useEventListener('keydown', (e) => {
    if (!isOpen) {
      return
    }

    if (e.key === 'Tab') {
      onBlur()
    }

    if (['ArrowDown', 'ArrowUp', 'Enter'].includes(e.key)) {
      e.preventDefault()
      e.stopPropagation()

      if (e.key === 'ArrowDown') {
        goToNextItem()
      } else if (e.key === 'ArrowUp') {
        goToPrevItem()
      } else if (e.key === 'Enter' && activeItem) {
        if (activeItem.onSelect) {
          activeItem.onSelect()
        } else {
          onSelect(activeItem.value)
        }
      }
    }
  })

  useLayoutEffect(() => {
    if (isOpen && scrollToItem) {
      goToItem(flatItems.findIndex(({ value }) => value === scrollToItem))
    }
  }, [isOpen, scrollToItem])

  useLayoutEffect(() => {
    if (!isOpen) {
      hoverItem(null)
    }
  }, [isOpen])

  const renderitems = useCallback((_items, startIdx = 0) => {
    return _items.map(({ onSelect: itemOnSelect, key, value, ...itemProps }, index) => {
      const flatIndex = startIdx + index
      return (
        <ListItem
          {...itemProps}
          ref={(el) => { itemsRef.current[flatIndex] = el }}
          isFocused={flatIndex === activeItemIndex}
          onPointerEnter={() => hoverItem(flatIndex)}
          onPointerLeave={() => hoverItem(null)}
          onSelect={() => itemOnSelect ? itemOnSelect() : onSelect(value)}
          key={key || value || flatIndex}
          rightAlign={rightAlign}
        />
      )
    })
  }, [activeItemIndex])

  const content = useMemo(() => (
    <ListItemsWrapper>
      {isGrouped ? (
        items.reduce((accum, groupItems, index) => {
          const { cnt } = accum

          accum.groups.push(
            <Group key={groupItems[0].key || index}>
              {renderitems(groupItems, cnt)}
            </Group>
          )
          accum.cnt = cnt + groupItems.length
          return accum
        }, { groups: [], cnt: 0 }).groups
      ) : (
        renderitems(items)
      )}
    </ListItemsWrapper>
  ), [activeItemIndex, items])

  return (
    <Dropdown
      ref={containerRef}
      content={items.length > 0 ? content : null}
      isOpen={isOpen}
      onBlur={onBlur}
      minWidth={minWidth || `${triggerRef.current?.offsetWidth || 0}px`}
      maxHeight={maxHeight}
      {...restProps}
    >
      <div ref={triggerRef}>
        {children}
      </div>
    </Dropdown>
  )
}

const ListItem = React.forwardRef(({
  icon: Icon,
  isDisabled,
  isChecked,
  isFocused,
  isKeyNav,
  onPointerEnter,
  onPointerLeave,
  onSelect,
  text,
  textStyles,
  caption,
  rightAlign,
}, ref) => {
  const { isWideMedia } = useContext(AppContext)

  return (
    <ListItemWrapper
      ref={ref}
      isFocused={isFocused}
      isKeyNav={isKeyNav}
      onClick={!isDisabled ? onSelect : null}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      isDisabled={isDisabled}
    >
      {Icon && (
        <ItemLeftIcon>
          <Icon />
        </ItemLeftIcon>
      )}
      <ItemText isDisabled={isDisabled} rightAlign={rightAlign}>
        <Text color='blackish' size={isWideMedia ? 14 : 16} style={textStyles}>{text}</Text>
        {caption && (
          <Text color='grey' size={12}>{caption}</Text>
        )}
      </ItemText>
      {isChecked && (
        <ItemRightIcon>
          <MdCheck />
        </ItemRightIcon>
      )}
    </ListItemWrapper>
  )
})

export default DropdownList


const ListItemsWrapper = styled.div`
  background-color: white;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0.5rem 0;
`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: solid 1px ${colors.greyLight};
  padding: 0.5rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
`

const ListItemWrapper = styled.div`
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  background-color: ${props => props.isFocused ? colors.greyLighter : colors.white};
`

const ItemText = styled.div`
  flex-grow: 1;
  line-height: 0;
  opacity: ${props => props.isDisabled ? 0.5 : 1};
  text-align: ${props => props.rightAlign ? 'right' : 'left'};

  span {
    display: block;
  }

  span + span {
    padding-top: 0.25rem;
  }
`

const ItemLeftIcon = styled.div`
  flex-shrink: 0;
  line-height: 0;

  > svg {
    fill: ${colors.greyDark};
    margin-right: 0.75rem;
    width: 1.125rem;
    height: 1.125rem;
  }
`

const ItemRightIcon = styled.div`
  flex-shrink: 0;
  line-height: 0;

  > svg {
    fill: ${colors.greyDark};
    margin-left: 0.75rem;
    width: 1.125rem;
    height: 1.125rem;
  }
`
