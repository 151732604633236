import { useRef } from 'react'
import styled from 'styled-components'

import Text from './Text'


const ColorPicker = ({ label, name, onChange, value, ...restProps }) => {
  const inputRef = useRef()

  return (
    <Label htmlFor={name} {...restProps}>
      <input
        id={name}
        ref={inputRef}
        type='color'
        onChange={(e) => onChange(name, e.target.value)}
        value={value}
      />
      <Swatch color={value} />
      <Text block>
        {label}
      </Text>
    </Label>
  )
}

export default ColorPicker

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  input {
    opacity: 0;
    width: 2.5rem;
    height: 2rem;
  }

  span {
    margin-left: 0.5rem;
  }
`

const Swatch = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${props => props.color};
  width: 2.5rem;
  height: 2rem;
  border-radius: 0.25rem;
  border: solid 1px rgba(255,255,255,.25);
`
