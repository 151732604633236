import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import AnchoredModal from './AnchoredModal'
import colors from '../_consts/colors'


const Dropdown = ({
  children,
  content,
  header,
  isOpen,
  maxHeight,
  minWidth,
  onBlur,
}, contentRef) => {
  const triggerRef = useRef()
  const [isReallyOpen, setIsReallyOpen] = useState(isOpen)

  useEffect(() => {
    let timeoutId

    if (isOpen) {
      setIsReallyOpen(true)
    } else {
      timeoutId = window.setTimeout(() => {
        setIsReallyOpen(false)
      }, 100)
    }

    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [isOpen])

  const willClose = !isOpen && isReallyOpen

  return (
    <>
      {isReallyOpen && (
        <AnchoredModal onBlur={onBlur} anchorNodeRef={triggerRef} willClose={willClose}>
          <Container minWidth={minWidth}>
            {header}
            <ContentWrapper maxHeight={maxHeight} minWidth={minWidth} ref={contentRef}>
              {content}
            </ContentWrapper>
          </Container>
        </AnchoredModal>
      )}
      <div ref={triggerRef}>
        {children}
      </div>
    </>
  )
}

export default React.forwardRef(Dropdown)

const Container = styled.div`
  box-shadow: 0 0.25rem 0.625rem rgba(0,0,0,0.35);
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: ${colors.white};
  min-width: ${props => props.minWidth || null};
`

const ContentWrapper = styled.div`
  min-width: ${props => props.minWidth || null};
  max-height: ${props => props.maxHeight || null};
  overflow: ${props => props.maxHeight ? 'auto' : null};
`
