import { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { IoFlask, IoFlaskOutline, IoPaperPlane, IoPaperPlaneOutline } from 'react-icons/io5'

import { ReactComponent as BirdyIcon } from './_assets/birdy.svg'
import { ReactComponent as BirdyFillIcon } from './_assets/birdy-fill.svg'
import { AppContext } from './AppProvider'
import tabs from './_consts/tabs'
import colors from './_consts/colors'


const options = [
  {
    value: tabs.text,
    title: 'Add or edit text',
    icon: BirdyIcon,
    iconSelected: BirdyFillIcon,
  },
  {
    value: tabs.style,
    title: 'Style it',
    icon: IoFlaskOutline,
    iconSelected: IoFlask,
  },
  {
    value: tabs.share,
    title: 'Get a shareable link',
    icon: IoPaperPlaneOutline,
    iconSelected: IoPaperPlane,
  },
]


const NavModern = () => {
  const {
    activeTab,
    setActiveTab,
    setIsPreviewing,
    isDarkBg,
    isPreviewing,
    isWideMedia,
  } = useContext(AppContext)

  const activeIndex = useMemo(() => (
    options.findIndex((option) => option.value === activeTab)
  ), [activeTab])

  const handleTabPress = useCallback((tab) => {
    setIsPreviewing(!isPreviewing && activeTab === tab)
    setActiveTab(tab)
  }, [activeTab, isPreviewing])

  return (
    <Container isWideMedia={isWideMedia} isPreviewing={isPreviewing}>
      {options.map(({ value, title, icon: Icon, iconSelected: IconSelected }, index) => (
        <TabButton
          key={value}
          onClick={() => handleTabPress(value)}
          selected={!isPreviewing && index === activeIndex}
          title={title}
          value={value}
          isDarkBg={isDarkBg}
          isWideMedia={isWideMedia}
        >
          {!isPreviewing && index === activeIndex ? <IconSelected /> : <Icon />}
        </TabButton>
      ))}
    </Container>
  )
}

export default NavModern

const Container = styled.div`
  position: relative;
  z-index: 5;
  height: 2.5rem;
  display: flex;
  justify-content: ${props => props.isWideMedia ? 'flex-end' : 'space-around'};
  margin: 0 2.5rem;
  opacity: ${props => props.isPreviewing ? 0.5 : 1};
`

const TabButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2rem);
  background-color: ${props => (
    props.selected ? 'rgba(230,230,230,0.75)' : 'rgba(230,230,230,0.35)'
  )};
  width: 4rem;
  margin-left: ${props => props.isWideMedia ? '1.25rem' : null};
  border-radius: 2rem;
  color: ${props => props.selected ? colors.accent :
        props.isDarkBg ? 'rgba(255,255,255,0.75)' :
        '#555555'};

  svg {
    flex-shrink: 0;
    height: 1.5rem;
    width: 1.5rem;
    fill: ${props => props.selected ? colors.accent :
        props.isDarkBg ? 'rgba(255,255,255,0.75)' :
        '#555555'};
  }
`
