import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { animated, easings, useTransition } from '@react-spring/web'

import { AppContext } from '../AppProvider'
import useFontSizeToFit from './hooks/useFontSizeToFit'
import Renderer from './Renderer'
import useCounter from './hooks/useCounter'
import useIsMounted from '../_hooks/useIsMounted'
import { AnimationContext } from '../AnimationProvider'
import useDebounce from '../_hooks/useDebounce'
import useShadowCharDims from './hooks/useShadowCharDims'


const WhisperReader = () => {
  const { config, displayTextEl } = useContext(AppContext)
  const {
    setIsAnimating,
    setStartAnimation,
    speedFactor,
    isAnimating,
  } = useContext(AnimationContext)
  const isMountedRef = useIsMounted()
  const timeoutRef = useRef()
  const counterEnter = useCounter()
  const counterLeave = useCounter()

  const { text } = config
  const trimmedText = text.trim()
  const shadowText = useDebounce(trimmedText)
  const { fontSize, text: displayText } = useFontSizeToFit(shadowText)
  const charDims = useShadowCharDims(displayText)

  const [enterCount, setEnterCount] = useState(null)
  const [leaveCount, setLeaveCount] = useState(null)

  useEffect(() => {
    setStartAnimation(() => {
      if (!fontSize || !charDims?.length || !isMountedRef.current || !displayTextEl) {
        return
      }

      setEnterCount(null)
      setLeaveCount(null)

      const charDelay = 200 / speedFactor
      const leaveDelay = charDelay * Math.min(15, charDims.length + 3)

      counterEnter({
        config: { delay: charDelay },
        size: charDims.length,
        onCount: setEnterCount,
      })

      counterLeave({
        config: { startDelay: leaveDelay, delay: charDelay },
        size: charDims.length,
        onCount: setLeaveCount,
      })
    })

    return () => {
      window.clearTimeout(timeoutRef.current)
    }
  }, [fontSize, charDims, speedFactor, displayTextEl])

  const transitions = useTransition(charDims?.slice(leaveCount, enterCount), {
    config: {
      duration: 700 / speedFactor,
      easing: easings.linear,
    },
    reset: !enterCount,
    from: { x: 0 },
    enter: { x: 1 },
    leave: { x: 0, leaving: true },
    onRest: () => {
      if (leaveCount === charDims.length) {
        window.requestAnimationFrame(() => {
          setIsAnimating(false)
        })
      }
    },
  })

  const getTransformEnter = useCallback((x, index) => {
    const interp = (1 - x) * (1 - x)

    return `
      translate3d(
        ${interp * fontSize * 2}px,
        ${interp * fontSize * -0.75}px,
        0px
      )
      rotate(${interp * -45}deg)
      scale(${1 + (interp * 0.75)})
    `
  }, [fontSize])

  const getTransformLeave = useCallback((x) => {
    const interp = (1 - x) * (1 - x)

    return `
      translate3d(
        ${-1 * interp * fontSize * 2}px,
        ${interp * fontSize * -0.75}px,
        0px
      )
      rotate(${-1 * interp * 45}deg)
      scale(${1 + (interp * 0.75)})
    `
  }, [fontSize])

  const displayTextHtml = charDims ?
    transitions((props, item) => (
      <animated.div
        key={item.index}
        style={{
          opacity: isAnimating ? props.x.to(x => x * x) : null,
          transform: isAnimating ?
            props.x.to(x => props.leaving ? getTransformLeave(x) : getTransformEnter(x)) :
            null,
          position: 'fixed',
          left: charDims[item.index].dims.left,
          top: charDims[item.index].dims.top,
        }}
      >
        {item.char}
      </animated.div>
    )) : null

  return (
    <Renderer
      centered
      padding='1rem'
      shadowText={shadowText}
      wrapShadowChars
      displayText={displayTextHtml}
      displayTextStyles={{ fontSize, width: '100%' }}
    />
  )
}


export default WhisperReader
