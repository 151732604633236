import { useMemo } from 'react'
import styled from 'styled-components'

import colors from '../_consts/colors'
import generateRand from '../_utils/generateRand'


const Stars = ({ total = 60 }) => {
  const stars = useMemo(() => {
    return Array.from(Array(total)).map((_, i) => {
      const size = generateRand() * 0.03
      return {
        styles: {
          left: `${generateRand()}%`,
          top: `${generateRand()}%`,
          height: size,
          width: size,
          opacity: generateRand() * 0.01,
        },
        key: i,
      }
    })
  }, [])

  return (
    <Container>
      {stars.map((star) => <div key={star.key} style={star.styles} />)}
    </Container>
  )
}

export default Stars

const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 0;
  pointer-events: none;

  div {
    position: absolute;
    border-radius: 50%;
    background-color: ${colors.white};
  }
`
