import { debounce } from 'lodash'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'


const useDimensions = (node, {
  watchWindowResize = false,
  watchWindowScroll = false,
  watchParams = [],
} = {}) => {
  const [dimensions, setDimensions] = useState(node?.getBoundingClientRect())
  const resizeObserverRef = useRef()
  const nodeRef = useRef(node)

  useEffect(() => {
    nodeRef.current = node
  }, [node])

  const updateDimensions = debounce(() => {
    setDimensions(nodeRef.current?.getBoundingClientRect())
  }, 100, { leading: true })

  useLayoutEffect(() => {
    if (node) {
      if (!resizeObserverRef.current) {
        resizeObserverRef.current = new ResizeObserver(() => {
          if (node) {
            updateDimensions()
          }
        })
      }

      resizeObserverRef.current.observe(node)

      if (watchWindowResize) {
        window.addEventListener('resize', updateDimensions)
      }

      if (watchWindowScroll) {
        window.addEventListener('scroll', updateDimensions)
      }

      updateDimensions()
    }

    return () => {
      if (resizeObserverRef?.current && node) {
        resizeObserverRef.current.unobserve(node)
      }

      if (watchWindowResize) {
        window.removeEventListener('resize', updateDimensions)
      }

      if (watchWindowScroll) {
        window.removeEventListener('scroll', updateDimensions)
      }
    }
  }, [node, ...watchParams])

  return dimensions
}

export default useDimensions
