import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { AppContext } from './AppProvider'


export const AnimationContext = React.createContext({})


export function AnimationProvider({ children }) {
  const { config, isEditMode, isPreviewing } = useContext(AppContext)
  const [isAnimating, setIsAnimating] = useState(false)
  const [startAnimationCb, setStartAnimationCb] = useState()

  const startAnimation = useCallback(() => {
    if (startAnimationCb) {
      setIsAnimating(false)
      window.requestAnimationFrame(() => {
        if (startAnimationCb) {
          setIsAnimating(true)
          startAnimationCb()
        }
      })
    }
  }, [startAnimationCb])

  const setStartAnimation = useCallback((cb) => {
    setStartAnimationCb(() => cb)
  }, [])

  // Reader and edit mode
  useEffect(() => {
    if (startAnimationCb) {
      startAnimation()
    }
  }, [startAnimationCb])

  // Restart on preview (hide tools)
  useEffect(() => {
    if (isEditMode && startAnimationCb && isPreviewing) {
      startAnimation()
    }
  }, [isPreviewing])

  useEffect(() => {
    if (!config.animation) {
      setIsAnimating(false)
    }
  }, [config.animation])

  const speedFactor = useMemo(() => {
    return parseFloat(config.animationSpeed || '1.0', 10)
  }, [config.animationSpeed])

  return (
    <AnimationContext.Provider
      value={{
        setStartAnimation,
        setIsAnimating,
        startAnimation,
        speedFactor,
        isAnimating,
        canAnimate: !!(config.animation && startAnimationCb),
      }}
    >
      {children}
    </AnimationContext.Provider>
  )
}
