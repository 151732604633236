import { useSpring, animated, config } from '@react-spring/web'
import styled from 'styled-components'
import { useContext } from 'react'

import { AppContext } from '../AppProvider'
import colors from '../_consts/colors'


const Spinner = () => {
  const { isDarkBg } = useContext(AppContext)

  const styles = useSpring({
    from: { opacity: 1, height: 0, width: 0 },
    to: { opacity: 0, height: 100, width: 100 },
    loop: true,
    config: config.slow,
  })

  return (
    <Container
      style={{
        ...styles,
        borderColor: isDarkBg ? colors.white : colors.grey,
      }}
    />
  )
}

export default Spinner

const Container = styled(animated.div)`;
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  border-width: 10px;
  border-style: solid;
`
