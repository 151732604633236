import styled from 'styled-components'
import { BsEye } from 'react-icons/bs'
import { useContext } from 'react'

import { AppContext } from '../AppProvider'
import Text from './Text'


const Impressions = () => {
  const { isDataLoaded, metaData, isDarkBg } = useContext(AppContext)

  return isDataLoaded ? (
    <Container isDarkBg={isDarkBg}>
      <BsEye />
      <Text>{metaData?.views}</Text>
    </Container>
  ) : null
}

export default Impressions

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.25rem;
  }
`
