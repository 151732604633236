import { animated } from '@react-spring/web'
import { Fragment, useContext, useMemo } from 'react'
import styled from 'styled-components'

import { AppContext } from '../AppProvider'
import { Fonts } from '../_consts/fonts'


const useTextStyles = () => {
  const { config } = useContext(AppContext)

  return useMemo(() => {
      const textDecorations = []
      if (config.underline) {
        textDecorations.push('underline')
      }
      if (config.lineThrough) {
        textDecorations.push('line-through')
      }
      if (config.overline) {
        textDecorations.push('overline')
      }
      const textDecoration = textDecorations.join(' ')

      return {
        color: config.textColor,
        fontFamily: `"${Fonts[config.font]}", sans-serif`,
        fontStyle: config.italic ? 'italic' : null,
        fontWeight: config.bold ? 'bold' : null,
        wordWrap: config.breakWords ? 'break-word' : null,
        wordBreak: config.breakWords ? 'break-word' : null,
        width: config.breakWords ? '100%' : null,
        textStroke: config.hasStroke ? `0.015em ${config.strokeColor}` : null,
        WebkitTextStroke: config.hasStroke ? `0.015em ${config.strokeColor}` : null,
        textAlign: config.textAlign,
        textShadow: config.hasShadow ? '0px 2px 40px #00000020, 0px 2px 5px #00000040' : null,
        textDecoration,
      }
  }, [config])
}


const useBgStyles = () => {
  const { config } = useContext(AppContext)

  return useMemo(() => {
    const transforms = []
    if (config.flipH) {
      transforms.push('rotateY(180deg)')
    }
    if (config.flipV) {
      transforms.push('rotateX(180deg)')
    }
    const transform = transforms.join(' ')

    return {
      backgroundColor: config.bgColor,
      transform,
    }
  }, [config])
}



const Renderer = ({
  children,
  shadowText,
  displayText,
  displayTextStyles,
  centered,
  padding = '0.25rem',
  perspective = 'auto',
  backScene,
  foreScene,
  wrapShadowChars,
  wrapShadowWords,
}) => {
  const {
    config,
    setBackgroundEl,
    setShadowTextEl,
    setDisplayTextEl,
  } = useContext(AppContext)
  const bgStyles = useBgStyles()
  const textStyles = useTextStyles()

  let shadowTextWrapped
  if (wrapShadowChars) {
    shadowTextWrapped = shadowText ? [...shadowText].map((char, i) => (
      <span key={i}>{char}</span>
    )) : []
  } else if (wrapShadowWords) {
    const words = shadowText.split(' ')
    shadowTextWrapped = shadowText ? words.map((word, i) => (
      <Fragment key={i}>
        <span>{word}</span>
        {i < words.length - 1 && ' '}
      </Fragment>
    )) : []
  } else {
    shadowTextWrapped = shadowText
  }

  return (
    <Background
      ref={setBackgroundEl}
      style={{ ...bgStyles, padding }}
    >
      <Content style={{ perspective }} centered={centered}>
        <ShadowText
          ref={setShadowTextEl}
          style={{
            ...textStyles,
            width: config.breakWords ? '100%' : null,
          }}
        >
          {shadowTextWrapped}
        </ShadowText>
        <TextWrapper style={displayTextStyles}>
          <TextWrapperInner
            ref={setDisplayTextEl}
            style={{
              ...textStyles,
              width: config.breakWords ? '100%' : null,
              visibility: displayText ? 'visible' : 'hidden',
            }}
          >
            {displayText}
          </TextWrapperInner>
        </TextWrapper>
      </Content>
      {backScene && (
        <BackScene>
          {backScene}
        </BackScene>
      )}
      {foreScene && (
        <ForeScene>
          {foreScene}
        </ForeScene>
      )}
      {children}
    </Background>
  )
}

export default Renderer

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  line-height: 100%;
  overflow: hidden;
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  ${props => props.centered && {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
`

const TextWrapper = styled(animated.div)`
  pointer-events: none;
  line-height: 1.15;
  white-space: pre-wrap;
  display: inline-block;
`

const ShadowText = styled(TextWrapper)`
  visibility: hidden;
  position: absolute;
  z-index: -1;
`

const TextWrapperInner = styled.div`
  white-space: inherit;
  pointer-events: none;
`

const BackScene = styled.div`
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const ForeScene = styled.div`
  z-index: 3;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
