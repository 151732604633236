export const Animations = {
  statesman: 'statesman',
  drop: 'drop',
  terminal: 'terminal',
  spaceConflicts: 'spaceConflicts',
  shuffle: 'shuffle',
  whisper: 'whisper',
  jumping: 'jumnping',
  zippy: 'zippy',
}

export const AnimationNames = {
  [Animations.statesman]: 'Statement',
  [Animations.drop]: 'Dropping In',
  [Animations.terminal]: 'Typewriter',
  [Animations.spaceConflicts]: 'Space Hostilities',
  [Animations.shuffle]: 'Tumbler',
  [Animations.whisper]: 'Whispers',
  [Animations.jumping]: 'Excited',
  [Animations.zippy]: 'Zippy',
}

export const AnimationSpeeds = {
  0.25: '0.25',
  0.5: '0.5',
  0.75: '0.75',
  1: '1',
  1.25: '1.25',
  1.5: '1.5',
  2: '2',
  3: '3',
  4: '4',
}
