import { useContext, useRef, useState } from 'react'
import styled from 'styled-components'

import Button from './Button'
import useIsMounted from '../_hooks/useIsMounted'
import colors from '../_consts/colors'
import { AppContext } from '../AppProvider'


const CopyLink = ({ link, toast }) => {
  const isMountedRef = useIsMounted()
  const { createToast } = useContext(AppContext)

  const inputRef = useRef()
  const timeoutRef = useRef()
  const [isCopied, setIsCopied] = useState(false)

  const selectAll = () => {
    inputRef.current.select()
  }

  const handleCopyLink = () => {
    window.clearTimeout(timeoutRef.current)
    selectAll()
    document.execCommand('copy')

    setIsCopied(true)
    if (toast) {
      createToast(toast)
    }
    timeoutRef.current = window.setTimeout(() => {
      if (isMountedRef.current) {
        setIsCopied(false)
      }
    }, 3000)
  }

  return (
    <Container>
      <LinkInput
        ref={inputRef}
        type='text'
        defaultValue={link}
        readOnly
        onFocus={selectAll}
        onClick={selectAll}
      />
      <Button
        disabled={isCopied}
        large
        primary
        padding='0 0.75rem'
        text='Copy'
        onClick={handleCopyLink}
        squareLeft
      />
    </Container>
  )
}

export default CopyLink

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const LinkInput = styled.input`
  width: 100%;
  font-size: 16px;
  color: ${colors.blackish};
  background-color: ${colors.white};
  border: solid 1px ${colors.greyMedium};
  padding: 0 0.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  cursor: text;
  border-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`
