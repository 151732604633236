import { useCallback, useContext, useState } from 'react'
import styled from 'styled-components'
import { useSpring, animated, easings } from '@react-spring/web'

import { AppContext } from './AppProvider'
import EditShare from './EditShare'
import EditStyle from './EditStyle'
import EditText from './EditText'
import ReadView from './ReadView'
import Tabs from './_consts/tabs'
import Nav from './Nav'


const EditPanes = {
  [Tabs.text]: EditText,
  [Tabs.style]: EditStyle,
  [Tabs.share]: EditShare,
}

const EditView = () => {
  const {
    activeTab,
    isPreviewing,
    isWideMedia,
    setIsPreviewing,
  } = useContext(AppContext)
  const [showEditPane, setShowEditPane] = useState(!isPreviewing)

  const handleReadViewTap = useCallback(() => {
    setIsPreviewing(true)
  }, [])

  const editViewInnerStyles = useSpring({
    config: {
      easing: easings.easeOutBack,
      duration: 150,
    },
    delay: 1,
    immediate: isWideMedia,
    transform: `scale(${isPreviewing ? 0.9 : 1})`,
    opacity: isPreviewing ? 0 : 1,
    onRest: () => {
      setShowEditPane(!isPreviewing)
    },
    onStart: () => {
      setShowEditPane(true)
    },
  })

  const EditPane = EditPanes[activeTab]

  const fillPane = activeTab === Tabs.text

  return (
    <>
      <ReadView onPointerUp={handleReadViewTap} />
      <EditViewWrapper isWideMedia={isWideMedia}>
        <Nav />
        <animated.div style={editViewInnerStyles}>
          {showEditPane && (
            <EditPaneWrapper fillPane={fillPane} isPreviewing={isPreviewing}>
              <EditPane />
            </EditPaneWrapper>
          )}
        </animated.div>
      </EditViewWrapper>
    </>
  )
}

export default EditView

const EditViewWrapper = styled.div`
  position: absolute;
  z-index: 3;
  right: ${props => props.isWideMedia ? '1rem' : null};
  top: ${props => props.isWideMedia ? '1rem' : '0.75rem'};
  width: 100%;
  max-width: ${props => props.isWideMedia ? '30rem' : null};
  border-radius: 1rem;
`

const EditPaneWrapper = styled.div`
  margin: 0.75rem 1rem 0;
  padding: ${props => props.fillPane ? null : '1rem'};
  backdrop-filter: blur(2rem);
  background-color: rgba(230,230,230,0.55);
  box-shadow: 0 0.125rem 0.75rem rgba(0,0,0,0.2);
  border-radius: 1rem;
  pointer-events: ${props => props.isPreviewing ? 'none' : null};
`
