import styled from 'styled-components'
import { Link } from 'react-router-dom'

import colors from '../_consts/colors'
import { ReactComponent as LogoIcon } from '../_assets/birdy.svg'
import Text from './Text'


const Logo = ({ color = colors.black, size = 30, to, withTitle = true }) => {
  const content = (
    <Container>
      <LogoIcon style={{ fill: color, color, width: size, height: 'auto' }} />
      {withTitle && (
        <TitleWrapper>
          <Text color={color} size={size} weight={500}>Wordydoo</Text>
        </TitleWrapper>
      )}
      <Text size='small' color={color}>™</Text>
    </Container>
  )

  return to ? <Link to={to} style={{ textDecoration: 'none' }}>{content}</Link> : content
}

export default Logo

const Container = styled.div`
  display: flex;
  align-items: center;
`

const TitleWrapper = styled.div`
  margin-left: 0.5rem;
`
