import colors from './colors'


const DefaultConfig = {
  animation: null,
  animationSpeed: 1,
  bgColor: '#ffffff',
  bold: false,
  breakWords: false,
  flipH: false,
  flipV: false,
  font: 'roboto',
  hasShadow: false,
  hasStroke: false,
  italic: false,
  lineThrough: false,
  overline: false,
  strokeColor: colors.grey,
  text: '',
  textColor: '#000000',
  textAlign: 'left',
  underline: false,
}

export default DefaultConfig
