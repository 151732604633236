import React from 'react'
import styled from 'styled-components'

import Button from './Button'


const ToggleButton = ({
  children,
  icon,
  isOn,
  onToggle,
  onSelect,
  name,
  value,
}) => {
  return (
    <Button
      accent={isOn}
      shade={isOn}
      blurImmediate
      large
      justifyContent='center'
      width='2rem'
      padding='0.5625rem'
      tertiary
      iconLeft={icon}
      text={children && (
        <TextWrapper>
          {children}
        </TextWrapper>
      )}
      onClick={() => {
        if (onSelect) {
          onSelect(name, value)
        } else {
          onToggle?.(name, !isOn)
        }
      }}
    />
  )
}

export default ToggleButton

const TextWrapper = styled.div`
  font-size: 1.1rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
