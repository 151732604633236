import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { useSpring, animated, easings } from '@react-spring/web'

import useDimensions from '../_hooks/useDimensions'
import useIsMounted from '../_hooks/useIsMounted'
import { ModalContext } from '../ModalProvider'


const directions = {
  ABOVE: 'above',
  BELOW: 'below',
  LEFT: 'left',
  RIGHT: 'right',
}


const orientations = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
}


function usePositionOffsets(modalRef, anchorNodeRef, anchorCoords, orientation) {
  const isMountedRef = useIsMounted()
  const anchorNodeDimensions = useDimensions(anchorNodeRef.current, { watchWindowResize: true })
  const modalDimensions = useDimensions(modalRef.current, { watchWindowResize: true })
  const [offsets, setOffsets] = useState(null)
  const { ABOVE, BELOW, LEFT, RIGHT } = directions
  const { HORIZONTAL } = orientations

  useEffect(() => {
    // Delay by frame to ensure anchor has updated dimensions before computing modal position.
    window.requestAnimationFrame(() => {
      if (!isMountedRef.current) {
        return
      }

      if ((!anchorCoords && !anchorNodeDimensions) || !modalDimensions) {
        setOffsets(null)
        return
      }

      let anchorLeft
      let anchorRight
      let anchorTop
      let anchorBottom

      if (anchorCoords) {
        anchorLeft = anchorCoords.x
        anchorRight = anchorCoords.x
        anchorTop = anchorCoords.y
        anchorBottom = anchorCoords.y
      } else {
        anchorLeft = anchorNodeDimensions.left
        anchorRight = anchorNodeDimensions.right
        anchorTop = anchorNodeDimensions.top
        anchorBottom = anchorNodeDimensions.bottom
      }

      const {
        height: modalHeight,
        width: modalWidth,
      } = modalDimensions

      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight
      const boundaryMargin = 16

      const anchorMarginHorizontal = anchorCoords ? 0 : 4
      const anchorMarginVertical = anchorCoords ? 0 : 4

      let requiredHorizontalSpace = modalWidth + boundaryMargin
      let requiredVerticalSpace = modalHeight + boundaryMargin

      if (orientation === HORIZONTAL) {
        requiredHorizontalSpace += anchorMarginHorizontal
      } else {
        requiredVerticalSpace += anchorMarginVertical
      }

      let left
      let top
      let direction

      if (orientation === HORIZONTAL) {
        left = anchorRight + anchorMarginHorizontal
        direction = RIGHT

        if (
          (anchorRight + requiredHorizontalSpace) > windowWidth &&
          (anchorLeft - requiredHorizontalSpace) > 0
        ) {
          left = anchorLeft - modalWidth - anchorMarginHorizontal
          direction = LEFT
        }

        if (anchorTop < boundaryMargin) {
          top = boundaryMargin
        } else if ((anchorTop + requiredVerticalSpace) < windowHeight) {
          top = anchorTop
        } else {
          top = windowHeight - modalHeight - boundaryMargin
        }
      } else {
        top = anchorBottom + anchorMarginVertical
        direction = BELOW

        if (
          (anchorBottom + requiredVerticalSpace) > windowHeight &&
          (anchorTop - requiredVerticalSpace) > 0
        ) {
          top = anchorTop - modalHeight - anchorMarginVertical
          direction = ABOVE
        }

        if (anchorLeft + requiredHorizontalSpace > windowWidth) {
          left = anchorRight - modalWidth
        } else {
          left = anchorLeft
        }
      }

      setOffsets({ left, top, direction })
    })
  }, [anchorCoords, anchorNodeDimensions, modalDimensions])

  return offsets
}


function useBlurListener(onBlur, modalRef, anchorNodeRef) {
  useEffect(() => {
    const handlePointerDown = (e) => {
      if (!modalRef.current?.contains(e.target) && !anchorNodeRef.current?.contains(e.target)) {
        onBlur(e)
      }
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Escape' || e.key === 'Tab' || e.key === 'Enter') {
        onBlur(e)
      }
    }

    window.addEventListener('pointerdown', handlePointerDown)
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('pointerdown', handlePointerDown)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
}


function AnchoredModal({
  modalName = 'anchoredModal',
  anchorCoords,
  anchorNodeRef,
  animationFactor = 1,
  animationDuration = 100,
  children,
  onBlur = () => {},
  orientation = orientations.VERTICAL,
  zIndex = 2,
  noPointerEvents = false,
  willClose = false,
}) {
  const { openAnchoredModal } = useContext(ModalContext)
  const shouldAnimate = !anchorCoords

  const isMountedRef = useIsMounted()
  const [isAnimatingIn, setIsAnimatingIn] = useState(shouldAnimate)
  const [isReady, setIsReady] = useState(false)
  const awaitRenderTimeoutId = useRef()

  const rootRef = useRef(document.getElementById('modal-root'))
  const modalRef = useRef()
  const positionOffsets = usePositionOffsets(modalRef, anchorNodeRef, anchorCoords, orientation)
  useBlurListener(onBlur, modalRef, anchorNodeRef)

  useEffect(() => {
    const closeAnchoredModal = openAnchoredModal(modalName)
    document.body.style.overflow = 'hidden'
    return () => {
      closeAnchoredModal()
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    if (positionOffsets) {
      awaitRenderTimeoutId.current = window.setTimeout(() => {
        if (isMountedRef.current) {
          setIsReady(true)
        }
      }, 1)
    }

    return () => {
      window.clearTimeout(awaitRenderTimeoutId.current)
    }
  }, [positionOffsets])

  const { direction } = positionOffsets || {}

  const [transX, transY] = (
    direction === directions.LEFT ? [1 * animationFactor, 0] :
    direction === directions.RIGHT ? [-1 * animationFactor, 0] :
    direction === directions.ABOVE ? [0, 1 * animationFactor] :
    direction === directions.BELOW ? [0, -1 * animationFactor] :
    [0, 0]
  )

  const animateContainerStyles = useSpring({
    config: {
      duration: animationDuration,
      easing: easings.easeOut,
    },
    immediate: !(shouldAnimate && direction),
    from: shouldAnimate && direction ? (
      willClose ? { transform: 'translate(0rem, 0rem) scale(1)', opacity: 1 } :
      { transform: `translate(${transX}rem, ${transY}rem) scale(0.98)`, opacity: 0 }
    ) : null,
    to: shouldAnimate && direction ? (
      willClose ? { transform: `translate(${transX}rem, ${transY}rem) scale(0.98)`, opacity: 0 } :
      { transform: 'translate(0rem, 0rem) scale(1)', opacity: 1 }
    ) : null,
    onRest: () => setIsAnimatingIn(false),
  })

  return ReactDOM.createPortal(
    <Container
      ref={modalRef}
      style={{
        ...animateContainerStyles,
        opacity: isReady ? animateContainerStyles.opacity : 0.01,
        left: positionOffsets?.left,
        top: positionOffsets?.top,
        zIndex,
        pointerEvents: noPointerEvents || isAnimatingIn ? 'none' : null,
      }}
    >
      {children}
    </Container>,
    rootRef.current,
  )
}

export default AnchoredModal

const Container = styled(animated.div)`
  position: fixed;
  will-change: transform, opacity;
`
