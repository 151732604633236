import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  updateDoc,
  getDoc,
  doc,
  setDoc,
  Timestamp,
} from 'firebase/firestore/lite'
import { getAnalytics } from 'firebase/analytics'
import { useNavigate, useParams } from 'react-router-dom'

import { Fonts } from './_consts/fonts'
import Tabs from './_consts/tabs'
import getIsDarkColor from './_utils/getIsDarkColor'
import Toast from './_components/Toast'
import generateShortId from './_utils/generateShortId'
import DefaultConfig from './_consts/defaultConfig'
import isEmoji from './_utils/isEmoji'


const DefaultDayAnalytics = {
  started: 0,
  completed: 0,
  views: 0,
}

const DefaultMetaData = {
  created: null,
  updated: null,
  views: 0,
}

const APP_NAME = 'Wordydoo'

const firebaseConfig = {
  apiKey: 'AIzaSyBXYFTWAAOldVgb35wIwE0PdjF0Cf12l2o',
  authDomain: 'wordydoo.firebaseapp.com',
  projectId: 'wordydoo',
  appId: '1:502174410079:web:ab212fc2102a64017202ea',
  measurementId: 'G-K51F84NC8X',
}

const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore()
getAnalytics(firebaseApp)

export const AppContext = React.createContext({})

const useData = (isEditMode) => {
  const navigate = useNavigate()
  const params = useParams()
  const [metaData, setMetaData] = useState(DefaultMetaData)
  const [config, setConfig] = useState(DefaultConfig)
  const [linkId, setLinkId] = useState(params.linkId)
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const date = new Date().toISOString().split('T')[0]

  const createLink = useCallback(async () => {
    if (db && !linkId) {
      try {
        const docId = generateShortId()
        const docRef = doc(db, 'links', docId)

        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          createLink()
          return
        }

        await setDoc(docRef, {
          created: Timestamp.now(),
          updated: Timestamp.now(),
          views: 0,
          config,
        })
        setLinkId(docId)
        navigate(`/${docId}/e`, { replace: true })

        const data = await getDayAnalytics()
        updateDayAnalytics({ completed: (data.completed || 0) + 1 })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error adding document: ', e)
      }
    }
  }, [config, db, linkId])

  const updateConfig = useCallback(async (updates) => {
    setConfig((prevConfigs) => ({
      ...prevConfigs,
      ...updates,
    }))

    if (db && linkId) {
      try {
        const docRef = doc(db, 'links', linkId)
        await updateDoc(docRef, {
          updated: Timestamp.now(),
          ...Object.entries(updates).reduce((accum, [key, value]) => ({
            ...accum,
            [`config.${key}`]: value,
          }), {}),
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error updating document: ', e)
      }
    }
  }, [config, db, linkId])

  const updateMeta = useCallback(async (updates) => {
    setMetaData((prevMeta) => ({
      ...prevMeta,
      ...updates,
    }))

    if (db && linkId) {
      try {
        const docRef = doc(db, 'links', linkId)
        await updateDoc(docRef, updates)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error updating document: ', e)
      }
    }
  }, [config, db, linkId])

  const updateDayAnalytics = useCallback(async (updates) => {
    if (db) {
      try {
        const docRef = doc(db, 'analytics', date)
        await updateDoc(docRef, updates)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error updating document: ', e)
      }
    }
  }, [db, date])

  const getDayAnalytics = useCallback(async () => {
    const docRef = doc(db, 'analytics', date)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return docSnap.data()
    }

    await setDoc(docRef, DefaultDayAnalytics)
    return DefaultDayAnalytics
  }, [db, date])

  const getLink = useCallback(async (_linkId) => {
    const docRef = doc(db, 'links', _linkId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const { config: _config, ..._meta } = docSnap.data()
      setMetaData(_meta)
      setConfig(_config)

      if (!isEditMode) {
        updateMeta({ views: (_meta?.views || 0) + 1 })
      }
    }
  }, [db])

  useEffect(() => {
    (async () => {
      if (db) {
        if (linkId) {
          await getLink(linkId)
          setIsDataLoaded(true)
        } else {
          setIsDataLoaded(true)
        }

        (async () => {
          const data = await getDayAnalytics()

          if (linkId && !isEditMode) {
            updateDayAnalytics({ views: (data.views || 0) + 1 })
          } else if (!linkId && isEditMode) {
            updateDayAnalytics({ started: (data.started || 0) + 1 })
          }
        })()
      }
    })()
  }, [db, isEditMode])

  return {
    isDataLoaded,
    config,
    metaData,
    linkId,
    createLink,
    updateConfig,
  }
}

function useIsFontLoaded(config) {
  const { font } = config
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(false)

    document.fonts.load(`16px ${Fonts[font]}`).then(() => {
      setIsLoaded(true)
    })
  }, [font])

  return isLoaded
}

function useCreateNew() {
  return useCallback(() => {
    window.location = window.location.origin
  }, [])
}

function useCreateToast() {
  const [toastContent, setToastContent] = useState()
  const [isToastVisible, setIsToastVisible] = useState()
  const timeoutRef = useRef()

  const createToast = useCallback((content, duration = 3000) => {
    window.clearTimeout(timeoutRef.current)
    setToastContent(content)
    setIsToastVisible(true)

    timeoutRef.current = window.setTimeout(() => {
      setIsToastVisible(false)
    }, duration)
  }, [])

  return {
    createToast,
    toastContent,
    isToastVisible,
  }
}

export const AppProvider = ({ children, appDims, isEditMode, isWideMedia }) => {
  const {
    isDataLoaded,
    config,
    metaData,
    linkId,
    createLink,
    updateConfig,
  } = useData(isEditMode)
  const isFontLoaded = useIsFontLoaded(config)
  const createNew = useCreateNew()
  const { createToast, toastContent, isToastVisible } = useCreateToast()
  const [activeTab, setActiveTab] = useState(Tabs.text)
  const [isPreviewing, setIsPreviewing] = useState(false)
  const [qrImageUrl, setQrImageUrl] = useState()
  const [backgroundEl, setBackgroundEl] = useState()
  const [shadowTextEl, setShadowTextEl] = useState()
  const [displayTextEl, setDisplayTextEl] = useState()
  const [previousFont, setPreviousFont] = useState()

  const isDarkBg = useMemo(() => (
    getIsDarkColor(config.bgColor)
  ), [config.bgColor])

  return (
    <AppContext.Provider
      value={{
        isDataLoaded,
        config,
        metaData,
        linkId,
        createLink,
        updateConfig,
        isDarkBg,
        activeTab,
        createNew,
        createToast,
        appDims,
        appName: APP_NAME,
        isEditMode,
        isFontLoaded,
        isPreviewing,
        isWideMedia,
        qrImageUrl,
        backgroundEl,
        shadowTextEl,
        displayTextEl,
        previousFont,
        setActiveTab,
        setIsPreviewing,
        setQrImageUrl,
        setBackgroundEl,
        setShadowTextEl,
        setDisplayTextEl,
        setPreviousFont,
      }}
    >
      {children}
      <Toast
        content={toastContent}
        isVisible={isToastVisible}
      />
    </AppContext.Provider>
  )
}
