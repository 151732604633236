import React, { useRef, useState } from 'react'
import uniqueId from 'lodash/uniqueId'


export const ModalContext = React.createContext({})


export function ModalProvider({ children }) {
  const modalIdsRef = useRef(new Set())
  const anchoredModalIdsRef = useRef(new Set())
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAnchoredModalOpen, setIsAnchoredModalOpen] = useState(false)

  const openModal = (idPrefix = 'modal') => {
    const modalId = uniqueId(idPrefix)
    modalIdsRef.current.add(modalId)
    setIsModalOpen(modalIdsRef.current.size > 0)

    const closeModal = () => {
      modalIdsRef.current.delete(modalId)
      setIsModalOpen(modalIdsRef.current.size > 0)
    }

    return closeModal
  }

  const openAnchoredModal = (idPrefix = 'anchoredModal') => {
    const anchoredModalId = uniqueId(idPrefix)
    anchoredModalIdsRef.current.add(anchoredModalId)
    setIsAnchoredModalOpen(anchoredModalIdsRef.current.size > 0)

    const closeAnchoredModal = () => {
      anchoredModalIdsRef.current.delete(anchoredModalId)
      setIsAnchoredModalOpen(anchoredModalIdsRef.current.size > 0)
    }

    return closeAnchoredModal
  }

  return (
    <ModalContext.Provider
      value={{
        isAnchoredModalOpen,
        isModalOpen,
        openAnchoredModal,
        openModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
