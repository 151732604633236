import { useContext, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useSpring, animated, easings } from '@react-spring/web'
import styled from 'styled-components'

import Text from './Text'
import colors from '../_consts/colors'
import { AppContext } from '../AppProvider'


const Toast = ({ content, isVisible = false }) => {
  const { isWideMedia } = useContext(AppContext)
  const rootRef = useRef(document.getElementById('toasts-root'))

  const styles = useSpring({
    config: {
      duration: 150,
      easing: easings.easeOut,
    },
    transform: `translateY(${isVisible ? 0 : 100}%)`,
    opacity: isVisible ? 1 : 0,
  })

  return ReactDOM.createPortal(
    <Container style={styles}>
      <ContainerInner isWideMedia={isWideMedia}>
        <Text color='white' size='large'>
          {content}
        </Text>
      </ContainerInner>
    </Container>,
    rootRef.current,
  )
}

export default Toast

const Container = styled(animated.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  will-change: transform, opacity;
`

const ContainerInner = styled.div`
  background-color: ${colors.black};
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  width: ${props => props.isWideMedia ? '20rem' : null};
`
