import { useCallback, useEffect, useRef } from 'react'

import useIsMounted from '../../_hooks/useIsMounted'
import generateRand from '../../_utils/generateRand'


const useCounter = () => {
  const startTimeoutRef = useRef()
  const countTimeoutRef = useRef()
  const isMountedRef = useIsMounted()

  const counter = useCallback(({
    config: {
      startDelay = 0,
      delay = 300,
      variance = 0,
    } = {},
    size,
    onCount,
    onStart,
    onDone,
  }) => {
    window.clearTimeout(startTimeoutRef.current)
    window.clearTimeout(countTimeoutRef.current)

    if (size && onCount) {
      let cnt = 0

      const add = () => {
        if (cnt === 0) {
          onStart?.()
        }
        if (cnt <= size && isMountedRef.current) {
          countTimeoutRef.current = window.setTimeout(() => {
            onCount(cnt)
            cnt += 1
            add()
          },
          variance ?
            generateRand(delay - (delay * variance), delay + (delay * variance)) :
            delay)
        } else {
          onDone?.()
        }
      }

      startTimeoutRef.current = window.setTimeout(add, startDelay)
    }
  }, [])

  useEffect(() => () => {
    window.clearTimeout(startTimeoutRef.current)
    window.clearTimeout(countTimeoutRef.current)
  }, [])

  return counter
}


export default useCounter
