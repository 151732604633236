import { useContext, useEffect, useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'

import { AppContext } from '../AppProvider'
import useFontSizeToFit from './hooks/useFontSizeToFit'
import Renderer from './Renderer'
import useIsMounted from '../_hooks/useIsMounted'
import { AnimationContext } from '../AnimationProvider'
import useDebounce from '../_hooks/useDebounce'
import useShadowCharDims from './hooks/useShadowCharDims'
import Fireworks from '../scenes/Fireworks'
import generateRand from '../_utils/generateRand'
import Stars from '../scenes/Stars'


const Char = ({ char, fontSize, speedFactor }) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const [animStyles, api] = useSpring(() => ({}))

  const from = {
    rotateY: '0deg',
    translateY: '0px',
  }

  const getRotateSpring = () => {
    return {
      delay: generateRand(0, 5000 / speedFactor),
      from,
      to: [
        { ...from, rotateY: `${generateRand(-5, 5) * 180}deg` },
        from,
      ],
      onRest: () => {
        setIsAnimating(false)
      },
    }
  }

  const getJumpSpring = () => {
    const rand = generateRand()

    return {
      delay: generateRand(0, 5000),
      from,
      to: [
        {
          ...from,
          translateY: `-${generateRand(0.25 * fontSize, Math.min(0.75 * fontSize))}px`,
          rotateY: rand > 85 ? '360deg' : '0deg',
        },
        from,
      ],
      onRest: () => {
        setIsAnimating(false)
      },
    }
  }

  const springs = [
    getRotateSpring,
    getJumpSpring,
  ]

  useEffect(() => {
    if (!isAnimating) {
      api.start(springs[generateRand(0, springs.length)])
      setIsAnimating(true)
    }
  }, [isAnimating])

  return (
    <animated.div
      style={{
        ...animStyles,
        transformOrigin: 'center center',
        position: 'fixed',
        left: char.dims.left,
        top: char.dims.top,
      }}
    >
      {char.char}
    </animated.div>
  )
}


const JumpingReader = () => {
  const { config } = useContext(AppContext)
  const {
    isAnimating,
    setIsAnimating,
    setStartAnimation,
    speedFactor,
  } = useContext(AnimationContext)
  const isMountedRef = useIsMounted()
  const showForeTimeoutIdRef = useRef()
  const [showFore, setShowFore] = useState(false)

  const shadowText = useDebounce(config.text.trim())
  const { fontSize, text: displayText } = useFontSizeToFit(shadowText)
  const charDims = useShadowCharDims(displayText)

  useEffect(() => {
    setStartAnimation(() => {
      window.clearTimeout(showForeTimeoutIdRef.current)

      if (!charDims?.length || !isMountedRef.current) {
        return
      }

      setIsAnimating(true)
      showForeTimeoutIdRef.current = window.setTimeout(() => {
        setShowFore(true)
      }, 5000 / speedFactor)
    })

    return () => {
      window.clearTimeout(showForeTimeoutIdRef.current)
    }
  }, [speedFactor, charDims])

  const displayTextHtml = isAnimating ? charDims?.map((char, i) => (
    <Char
      key={i}
      char={char}
      fontSize={fontSize}
      speedFactor={speedFactor}
    />
  )) : displayText

  return (
    <Renderer
      centered
      shadowText={shadowText}
      wrapShadowChars
      displayText={displayTextHtml}
      displayTextStyles={{ fontSize }}
      backScene={(
        <>
          <Stars />
          <Fireworks intensity={5 * speedFactor} />
        </>
      )}
      foreScene={showFore && <Fireworks intensity={3 * speedFactor} />}
    />
  )
}


export default JumpingReader
