import { useEffect, useRef } from 'react'


function useEventListener(eventName, callback, el = window, options) {
  const callbackRef = useRef()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    // Protect against elements that don't support adding listeners.
    if (!el?.addEventListener) {
      return null
    }

    const handleEvent = (...args) => callbackRef.current(...args)

    el.addEventListener(eventName, handleEvent, options)

    return () => { el.removeEventListener(eventName, handleEvent) }
  }, [eventName, el])
}

export default useEventListener
