import { useCallback, useContext, useEffect } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import styled from 'styled-components'

import { AppContext } from './AppProvider'
import Button from './_components/Button'
import CopyLink from './_components/CopyLink'
import Section from './_components/Section'
import Spinner from './_components/Spinner'
import Text from './_components/Text'
import Copyright from './_components/Copyright'
import downloadQRCode from './_utils/downloadQRCode'


const EditShare = () => {
  const {
    appName,
    createLink,
    createNew,
    linkId,
    config,
    qrImageUrl,
    setQrImageUrl,
  } = useContext(AppContext)
  const linkUrl = linkId && `${window.location.origin}/${linkId}`

  useEffect(() => {
    if (config.text && !linkId) {
      createLink()
    }
  }, [config, linkId])

  useEffect(() => {
    if (linkId && !qrImageUrl) {
      window.requestAnimationFrame(() => {
        const imageUrl = document.getElementById('qrCanvas').toDataURL('image/png')
        setQrImageUrl(imageUrl)
      })
    }
  }, [linkId, qrImageUrl])

  const handleDownloadQR = useCallback(() => {
    downloadQRCode(qrImageUrl)
  }, [qrImageUrl])

  const copyright = (
    <CopyrightWrapper>
      <Copyright to='/about' />
    </CopyrightWrapper>
  )

  if (!config.text && !linkId) {
    return (
      <Container>
        <CenteredContainer>
          <Text size='large' color='greyDark'>
            Go back and write something. Then come here to share it.
          </Text>
        </CenteredContainer>
        {copyright}
      </Container>
    )
  }

  const qrSize = Math.min(window.innerWidth * 0.6, 180)

  return (
    <Container>
      {linkUrl ? (
        <>
          <Section label={`Share your ${appName} link:`}>
            <CopyLink link={linkUrl} toast='Link copied to clipboard' />
          </Section>
          <Section label='Or share your QR code:'>
            <QRCodeWrapper size={qrSize}>
              {!qrImageUrl && (
                <QRCodeCanvas
                  id='qrCanvas'
                  size={qrSize}
                  value={linkUrl}
                />
              )}
              {qrImageUrl && (
                <img
                  id='qrImage'
                  src={qrImageUrl}
                  alt='QR Code'
                  title='Wordydoo'
                  width={qrSize}
                  height={qrSize}
                />
              )}
            </QRCodeWrapper>
            <QTButtonWrapper size={qrSize}>
              <Button
                primary
                text='Save'
                onClick={handleDownloadQR}
              />
            </QTButtonWrapper>
          </Section>
          <Section separator>
            <Centered>
              <Button
                large
                secondary
                text={`+ Create another ${appName}`}
                onClick={createNew}
              />
            </Centered>
          </Section>
        </>
      ) : (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {copyright}
    </Container>
  )
}

export default EditShare

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 10rem;
`

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CenteredContainer = styled(Centered)`
  min-height: 10rem;
`

const QRCodeWrapper = styled.div`
  margin: 0 auto;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`

const QTButtonWrapper = styled.div`
  margin: 0.25rem auto 0 auto;
  width: ${props => props.size}px;
  display: flex;
  justify-content: flex-end;
`

const SpinnerWrapper = styled.div`
  height: 10rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CopyrightWrapper = styled.div`
  align-self: flex-end;
  justify-content: flex-end;
  position: relative;
  bottom: -0.25rem;
`
