import { useEffect, useRef } from 'react'


function useScrollToItem(items, itemIndex, canScroll) {
  const containerRef = useRef()
  const itemsRef = useRef([])

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, items.length)
  }, [items.length])

  const containerEl = containerRef.current
  const scrollToEl = itemsRef.current?.[itemIndex]

  useEffect(() => {
    if (containerEl && scrollToEl && canScroll) {
      const containerHeight = containerEl.offsetHeight
      const containerScrollTop = containerEl.scrollTop
      const resultHeight = scrollToEl.offsetHeight
      const resultTop = scrollToEl.offsetTop

      if (resultTop < containerScrollTop) {
        containerEl.scrollTo(0, resultTop)
      } else if (resultTop + resultHeight > containerScrollTop + containerHeight) {
        containerEl.scrollTo(0, (resultTop + resultHeight) - containerHeight)
      }
    }
  }, [containerEl, scrollToEl, canScroll])

  return {
    containerRef,
    itemsRef,
  }
}

export default useScrollToItem
