import { useContext } from 'react'

import { AppContext } from '../AppProvider'
import useFontSizeToFit from './hooks/useFontSizeToFit'
import Renderer from './Renderer'


const PlainReader = () => {
  const { config } = useContext(AppContext)
  const shadowText = config.text.trim()
  const { fontSize, text: displayText } = useFontSizeToFit(shadowText)

  return (
    <Renderer
      centered
      shadowText={shadowText}
      displayText={displayText}
      displayTextStyles={{ fontSize }}
    />
  )
}


export default PlainReader
