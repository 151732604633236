import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Text from './Text'


const Copyright = ({ to }) => {
  const name = 'Wordydoo Software, 2022'

  return (
    <Container>
      <Text size='small'>
        © {to ? <Link to={to}>{name}</Link> : name}
      </Text>
    </Container>
  )
}


export default Copyright

const Container = styled.div`
  a {
    color: inherit;
  }
`
