import { useEffect } from 'react'
import { animated, easings, useSpring } from '@react-spring/web'
import styled from 'styled-components'


const Caret = ({ color, coords, fontSize }) => {
  const animProps = {
    loop: true,
    reset: true,
    from: { x: 0 },
    to: { x: 1 },
  }

  const [animStyles, api] = useSpring(() => ({
    config: {
      easing: easings.linear,
      duration: 700,
    },
    ...animProps,
  }))

  const opacity = animStyles?.x?.to({
    range: [0, 0.1, 0.5, 0.6, 1],
    output: [0, 0.75, 0.75, 0, 0],
  })

  useEffect(() => {
    api.start(animProps)
  }, [])

  if (!color || !fontSize || !coords?.x || !coords.y) {
    return null
  }

  const height = fontSize * 1.15

  return (
    <Container
      style={{
        ...animStyles,
        opacity,
        backgroundColor: color,
        left: coords.x,
        top: coords.y,
        height,
        width: Math.max(height / 60, 1.5),
      }}
    />
  )
}

export default Caret

const Container = styled(animated.div)`
  position: fixed;
`
