function downloadQRCode(dataImageUrl) {
  const dataUrl = dataImageUrl.replace('image/png', 'image/octet-stream')
  const aEl = document.createElement('a')
  aEl.href = dataUrl
  aEl.download = 'wordydooQR.png'
  document.body.appendChild(aEl)
  aEl.click()
  document.body.removeChild(aEl)
}

export default downloadQRCode
