import styled from 'styled-components'
import { MdArrowDropDown } from 'react-icons/md'

import Text from './Text'
import colors from '../_consts/colors'


const DropdownButton = ({
  color = 'blackish',
  hasShading = false,
  hoverColor,
  fontSize = 14,
  icon: Icon,
  text,
  textStyles,
  ...restProps
}) => {
  return (
    <Container
      {...restProps}
      color={color}
      hasShading={hasShading}
      hoverColor={hoverColor || color}
    >
      <div>
        {Icon && <Icon />}
        <Text size={fontSize} color={color} style={textStyles}>
          {text}
        </Text>
      </div>
      <MdArrowDropDown />
    </Container>
  )
}

export default DropdownButton

const Container = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.width ?? 'auto'};
  padding: ${props => props.padding ?? '0 0.75rem'};
  min-height: ${props => props.minHeight ?? '2.5rem'};
  background-color: ${props => props.hasShading ? 'rgba(255,255,255,0.75)' : null};
  border: ${props => props.hasBorder ? 'solid 1px ' + colors.greyLight : 0};
  border-radius: 0.25rem;
  transition: border-color 150ms;
  cursor: pointer;

  span {
    max-width: ${props => props.maxWidth ?? 'auto'};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:focus {
    border-color: ${props => props.hasBorder ? colors.greyMedium : null};
    color: ${props => colors[props.hoverColor]};

    span {
      color: ${props => colors[props.hoverColor]};
    }
    > svg {
      fill: ${props => colors[props.hoverColor]};
    }
  }

  &:hover {
    color: ${props => colors[props.hoverColor]};

    span {
      color: ${props => colors[props.hoverColor]};
    }
    > svg {
      fill: ${props => colors[props.hoverColor]};
    }
  }

  > div {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 0.25rem;

    svg {
      margin-right: 0.625rem;
      fill: ${colors.greyDark};
    }
  }

  svg {
    fill: ${props => colors[props.color || 'blackish']};
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    transition: fill 150ms;
  }
`
