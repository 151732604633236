import { useRef } from 'react'
import styled from 'styled-components'

import Text from './Text'
import useIsMounted from '../_hooks/useIsMounted'
import colors from '../_consts/colors'


const Button = ({
  accent,
  blurImmediate,
  iconLeft: IconLeft,
  justifyContent = 'center',
  large,
  small,
  onClick = () => {},
  padding,
  primary,
  warning,
  secondary,
  shade,
  shadeHover,
  tertiary,
  text,
  ...restProps
}) => {
  const containerRef = useRef()
  const isMountedRef = useIsMounted()

  const handleClick = (e) => {
    onClick(e)

    if (e.detail !== 0) {
      window.setTimeout(() => {
        if (isMountedRef.current) {
          containerRef.current.blur()
        }
      }, blurImmediate ? 0 : 100)
    }
  }

  return (
    <Container
      ref={containerRef}
      onClick={handleClick}
      accent={accent}
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      justifyContent={justifyContent}
      large={large}
      padding={padding}
      small={small}
      shade={shade}
      shadeHover={shadeHover}
      warning={warning}
      {...restProps}
    >
      {IconLeft && (
        <IconLeftWrapper noMargin={!text}>
          <IconLeft />
        </IconLeftWrapper>
      )}
      {text && (
        <Text
          block
          color={accent ? 'accent' : 'greyDark'}
          size={large && primary ? 16 : small ? 13 : 14}
          weight={500}
        >
          {text}
        </Text>
      )}
    </Container>
  )
}

export default Button

const Container = styled.button`
  display: flex;
  padding: ${props => props.padding ??
    (props.large ? '0 1.25rem' : props.small ? '0 0.625rem' : '0 0.75rem')
  };
  align-items: center;
  justify-content: ${props => props.justifyContent};
  border-radius: 0.25rem;
  height: ${props => props.large ? 2.5 : props.small ? 1.75 : 2}rem;
  min-width: ${props => props.width || 'auto'};
  transition: background-color 150ms;
  background-color: ${props => {
    if (props.primary) {
      return props.disabled ? colors.greyMedium : colors.accent
    }
    if (props.secondary || props.accent) {
      return props.shade ?
        (props.warning ? colors.warningLighter : colors.accentLighter) :
        colors.white
    }
    return 'rgba(255,255,255,0.75)'
  }};
  border: solid 1px ${props => (props.secondary || props.accent) ?
    props.disabled ? colors.greyMedium :
      (props.warning ? colors.warning : colors.accent) :
      'transparent'
  };
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  ${props => props.squareLeft && {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  }}

  > span {
    color: ${(props) => {
      if (props.accent) {
        return colors.accent
      }
      if (props.primary) {
        return colors.white
      }
      if (props.secondary) {
        return props.disabled ? colors.greyMedium :
          props.warning ? colors.warning :
          colors.accent
      }
      return props.disabled ? colors.greyMedium : colors.greyDark
    }};
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: ${(props) => {
      if (props.accent) {
        return colors.accent
      }
      if (props.primary) {
        return colors.white
      }
      if (props.secondary) {
        return props.disabled ? colors.greyMedium :
          props.warning ? colors.warning :
          colors.accent
      }
      return colors.greyDark
    }};
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${props => {
        if (props.disabled || props.accent) {
          return null
        }
        if (props.primary) {
          return colors.accentDark
        }
        if (props.secondary) {
          return props.shade || props.shadeHover ?
            (props.warning ? colors.warningLighter : colors.accentLighter) :
            colors.white
        }
        return colors.greyLight
      }};
      border: solid 1px ${props => (props.secondary || props.accent) ?
        props.disabled ? colors.greyMedium :
        (props.warning ? colors.warningDarker : colors.accentDark) :
        'transparent'
      };

      > span {
        color: ${props => {
          if (props.accent) {
            return colors.accent
          }
          if (props.primary) {
            return colors.white
          }
          if (props.secondary) {
            return props.disabled ? colors.greyMedium :
              props.warning ? colors.warningDarker :
              colors.accentDark
          }
          return props.disabled ? colors.greyMedium : colors.greyDarker
        }};
      }
    }
  }

  &:focus {
    background-color: ${props => {
      if (props.disabled) {
        return null
      }
      if (props.primary) {
        return colors.accentDark
      }
      if (props.secondary) {
        return props.shade || props.shadeHover ?
          (props.warning ? colors.warningLighter : colors.accentLighter) :
          colors.white
      }
      return colors.greyMediumLight
    }};
    border: solid 1px
      ${props => (props.primary || props.secondary) ?
        props.disabled ? colors.greyMedium :
        (props.warning ? colors.warningDarker : colors.accentDarker) :
        'transparent'
      };

    > span {
      color: ${props => {
        if (props.accent) {
          return colors.accent
        }
        if (props.primary) {
          return colors.white
        }
        if (props.secondary) {
          return props.disabled ? colors.greyMedium :
            props.warning ? colors.warningDarker :
            colors.accentDarker
        }
        return props.disabled ? colors.greyMedium : colors.greyDarker
      }};
    }
  }
`

const IconLeftWrapper = styled.div`
  padding-right: ${props => props.noMargin ? 0 : 0.5}rem;
  line-height: 0;
`
