import { useEffect, useRef, useState } from 'react'

import useEventListener from './useEventListener'


function useActiveListItem(items, autoHighlight, inputValue, isInputFocused) {
  const initialActiveIndex = autoHighlight ? 0 : null
  const numItems = items.length

  const allowHoverRef = useRef()
  const [activeItemIndex, setActiveItemIndex] = useState(initialActiveIndex)
  const [canScrollToActive, setCanScrollToActive] = useState(false)

  useEventListener('pointermove', () => {
    allowHoverRef.current = true
  })

  useEffect(() => {
    setCanScrollToActive(false)
    setActiveItemIndex(initialActiveIndex)
  }, [inputValue, isInputFocused])

  const goToNextItem = () => {
    allowHoverRef.current = false
    setCanScrollToActive(true)

    if (numItems === 0) {
      return
    }

    if (!activeItemIndex && activeItemIndex !== 0) {
      setActiveItemIndex(0)
    } else {
      const isLastActive = activeItemIndex === numItems - 1
      setActiveItemIndex(isLastActive ? 0 : activeItemIndex + 1)
    }
  }

  const goToPrevItem = () => {
    allowHoverRef.current = false
    setCanScrollToActive(true)

    if (numItems === 0) {
      return
    }

    if (!activeItemIndex && activeItemIndex !== 0) {
      setActiveItemIndex(numItems - 1)
    } else {
      const isFirstActive = activeItemIndex === 0
      setActiveItemIndex(isFirstActive ? numItems - 1 : activeItemIndex - 1)
    }
  }

  const goToItem = (index) => {
    allowHoverRef.current = false
    setCanScrollToActive(true)
    setActiveItemIndex(index)
  }

  const hoverItem = (index) => {
    if (allowHoverRef.current) {
      setCanScrollToActive(false)
      setActiveItemIndex(index)
    }
  }

  return {
    activeItem: items[activeItemIndex],
    activeItemIndex,
    canScrollToActive,
    goToNextItem,
    goToPrevItem,
    goToItem,
    hoverItem,
  }
}

export default useActiveListItem
