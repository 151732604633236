import { useContext, useEffect, useRef, useState } from 'react'

import { AppContext } from '../../AppProvider'
import useDimensions from '../../_hooks/useDimensions'
import usePrevious from '../../_hooks/usePrevious'


const useFontSizeToFit = (text, { minSize = 16, maxSize = 1000 } = {}) => {
  const { backgroundEl, config, shadowTextEl } = useContext(AppContext)
  const animFrameRef = useRef()
  const fontSizeRef = useRef()
  const [fontSize, setFontSize] = useState(null)
  const [retText, setRetText] = useState(null)
  const previousText = usePrevious(text)
  const bgDims = useDimensions(backgroundEl, { watchWindowResize: true })
  const maxFontSize = bgDims ? Math.min(bgDims.width, bgDims.height, maxSize) : maxSize

  const updateShadowDom = () => {
    if (shadowTextEl) {
      shadowTextEl.style.fontSize = `${fontSizeRef.current}px`
    }
  }

  const shrinkIfNecessary = () => {
    if (!bgDims?.height || !shadowTextEl?.offsetHeight) {
      return
    }

    const padding = parseInt(
      window.getComputedStyle(backgroundEl, null).getPropertyValue('padding').slice(0, -2),
      10
    )
    const bgHeight = bgDims.height - (padding * 2)
    const bgWidth = bgDims.width - (padding * 2)

    if (
      (
        shadowTextEl.offsetHeight <= bgHeight &&
        shadowTextEl.offsetWidth <= bgWidth
      ) ||
      fontSizeRef.current <= minSize
    ) {
      setFontSize(fontSizeRef.current)
      setRetText(text)
      return
    }

    fontSizeRef.current -= 1
    updateShadowDom()
    shrinkIfNecessary()
  }

  useEffect(() => {
    if (text && shadowTextEl && bgDims) {
      animFrameRef.current = window.requestAnimationFrame(() => {
        if (!previousText || text.length <= previousText.length) {
          fontSizeRef.current = maxFontSize
        }
        updateShadowDom()
        shrinkIfNecessary()
      })
    } else {
      setFontSize(null)
      setRetText(null)
    }

    return () => {
      window.cancelAnimationFrame(animFrameRef.current)
    }
  }, [maxFontSize, text, config, shadowTextEl, bgDims])

  return { fontSize, text: retText }
}

export default useFontSizeToFit
