import { useContext } from 'react'
import styled from 'styled-components'

import { AppContext } from '../AppProvider'
import colors from '../_consts/colors'


const sizes = {
  xlarge: 18,
  large: 16,
  regular: 14,
  small: 12,
  xsmall: 10,
}

const lineHeights = (size) => ({
  tight: size * 1.1,
  regular: size * 1.3,
  spaced: size * 1.625,
})

const Text = ({
  block,
  children,
  color,
  lineHeight = 'regular',
  size = 'regular',
  weight,
  preventTransition = false,
  tooltip,
  ...restProps
 }) => {
  const { isDarkBg } = useContext(AppContext)

  return (
    <Container
      isDarkBg={isDarkBg}
      block={block}
      color={color}
      lineHeight={lineHeight}
      size={size}
      weight={weight}
      preventTransition={preventTransition}
      {...restProps}
    >
      {children}
    </Container>
  )
}

export default Text

const Container = styled.span(({
  block,
  children,
  color,
  isDarkBg,
  lineHeight,
  monospace,
  nowrap,
  pre,
  size,
  theme,
  uppercase,
  weight,
  preventTransition,
  ...restProps
}) => {
  const _size = sizes[size] || size
  const _lineHeight = lineHeights(_size)[lineHeight] || lineHeight

  return {
    display: block ? 'block' : 'inline',
    transition: preventTransition ? 'none' : 'opacity 150ms, color 150ms',
    color: color ? (colors[color] || color) :
      (isDarkBg ? colors.white : colors.blackish),
    fontFamily: monospace ? 'monospace' : null,
    fontSize: `${_size}px`,
    fontWeight: weight,
    lineHeight: _lineHeight ? `${_lineHeight}px` : 'normal',
    textTransform: uppercase ? 'uppercase' : null,
    whiteSpace: nowrap ? 'nowrap' : pre ? 'pre-wrap' : 'normal',
    ...restProps,
  }
})
