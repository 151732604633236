import { useContext, useEffect, useRef, useState } from 'react'

import { AppContext } from '../AppProvider'
import useFontSizeToFit from './hooks/useFontSizeToFit'
import Renderer from './Renderer'
import useCounter from './hooks/useCounter'
import useIsMounted from '../_hooks/useIsMounted'
import { AnimationContext } from '../AnimationProvider'
import useDebounce from '../_hooks/useDebounce'
import generateRand from '../_utils/generateRand'
import Palette from '../_consts/palette'


const Alpha = [
  'A', 'B', 'C', 'D',
  'E', 'F', 'G', 'H',
  'I', 'J', 'K', 'L',
  'M', 'N', 'O', 'P',
  'Q', 'R', 'S', 'T',
  'U', 'V', 'W', 'X',
  'Y', 'Z',
]

const ShuffleReader = () => {
  const { config, displayTextEl } = useContext(AppContext)
  const {
    setIsAnimating,
    setStartAnimation,
    speedFactor,
  } = useContext(AnimationContext)
  const isMountedRef = useIsMounted()
  const counter = useCounter()
  const hasStartedRef = useRef()
  const intervalIdRef = useRef()

  const { text } = config
  const trimmedText = text.trim()
  const shadowText = useDebounce(trimmedText)
  const { fontSize, text: displayText } = useFontSizeToFit(shadowText)
  const [displayIndex, setDisplayIndex] = useState(null)

  useEffect(() => {
    setStartAnimation(() => {
      window.clearInterval(intervalIdRef.current)

      if (!displayText?.length || !isMountedRef.current || !displayTextEl) {
        return
      }
      setDisplayIndex(null)

      counter({
        config: {
          startDelay: 1500,
          delay: 400 / speedFactor,
        },
        size: [...displayText].length,
        onCount: (cnt) => {
          setDisplayIndex(cnt)
        },
        onStart: () => {
          hasStartedRef.current = true
        },
        onDone: () => {
          window.clearInterval(intervalIdRef.current)
          setIsAnimating(false)
          hasStartedRef.current = false
        },
      })

      intervalIdRef.current = window.setInterval(() => {
        let obfCount = 0
        const spans = Array.from(displayTextEl?.children)

        spans.forEach((span) => {
          const obfuscate = span.dataset.obfuscated === 'true'

          if (obfuscate) {
            obfCount += 1
            const charIdx = generateRand(0, Alpha.length)
            const lowercase = generateRand() < 75
            const char = Alpha[charIdx]
            span.innerHTML = lowercase ? char.toLowerCase() : char

            if (obfCount > 1 || !hasStartedRef.current) {
              const colorIdx = generateRand(0, Palette.length)
              span.style.color = Palette[colorIdx]
            }
          }

          if (hasStartedRef.current && (!obfuscate || obfCount <= 1)) {
            span.style.color = null
          }
        })
      }, 75)
    })

    return () => {
      window.clearInterval(intervalIdRef.current)
    }
  }, [displayText, speedFactor, displayTextEl])

  const displayTextHtml = displayText ? [...displayText].map((char, i) => {
    const obfuscated = char !== ' ' && (displayIndex === null || i > displayIndex)

    return (
      <span
        data-obfuscated={obfuscated}
        key={i}
      >
        {obfuscated ? ' ' : char}
      </span>
    )
  }) : null

  return (
    <Renderer
      padding='1rem'
      centered
      shadowText={shadowText}
      displayText={displayTextHtml}
      displayTextStyles={{ fontSize }}
    />
  )
}


export default ShuffleReader
