import { useContext, useEffect, useState } from 'react'

import { AppContext } from '../AppProvider'
import useFontSizeToFit from './hooks/useFontSizeToFit'
import Renderer from './Renderer'
import useCounter from './hooks/useCounter'
import useIsMounted from '../_hooks/useIsMounted'
import { AnimationContext } from '../AnimationProvider'
import useDebounce from '../_hooks/useDebounce'


const StatesmanReader = () => {
  const { config } = useContext(AppContext)
  const { setIsAnimating, setStartAnimation, speedFactor } = useContext(AnimationContext)
  const isMountedRef = useIsMounted()
  const counter = useCounter()

  const trimmedText = config.text.trim()
  const debouncedText = useDebounce(trimmedText)
  const [shadowText, setShadowText] = useState('')

  useEffect(() => {
    setStartAnimation(() => {
      if (!debouncedText?.length || !isMountedRef.current) {
        return
      }

      setShadowText('')
      counter({
        config: {
          delay: 250 / speedFactor,
        },
        size: trimmedText?.length,
        onCount: (cnt) => setShadowText(debouncedText.slice(0, cnt)),
        onDone: () => setIsAnimating(false),
      })
    })
  }, [speedFactor, debouncedText])

  const { fontSize, text: displayText } = useFontSizeToFit(shadowText)

  return (
    <Renderer
      centered
      shadowText={shadowText}
      displayText={displayText}
      displayTextStyles={{ fontSize }}
    />
  )
}


export default StatesmanReader
