import { useContext, useEffect, useState } from 'react'

import { AppContext } from '../../AppProvider'


const useShadowCharDims = (displayText) => {
  const { appDims, config, shadowTextEl } = useContext(AppContext)
  const [chars, setChars] = useState(null)

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (displayText && shadowTextEl?.children) {
        setChars(
          Array.from(shadowTextEl.children).map((el, index) => ({
            index,
            char: el.innerHTML,
            dims: el.getBoundingClientRect(),
          }))
        )
      } else {
        setChars(null)
      }
    })
  }, [appDims, config, shadowTextEl, displayText])

  return chars
}

export default useShadowCharDims
