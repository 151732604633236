import { Fireworks as FireworksLib } from '@fireworks-js/react'


const Fireworks = ({ intensity = 5 }) => {
  return (
    <FireworksLib
      options={{
        intensity,
      }}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  )
}

export default Fireworks
