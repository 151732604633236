import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import TextareaAutoSize from 'react-autosize-textarea'

import colors from '../_consts/colors'
import { AppContext } from '../AppProvider'


const InputTextarea = ({
  autoFocus,
  color = 'blackish',
  disabled,
  fontSize = 14,
  padding = '0.75rem',
  maxRows = 20,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  placeholder,
  required,
  rows = 1,
  focusRows,
  value,
  withStyles,
  ...restProps
}, ref) => {
  const { isWideMedia, isDarkBg } = useContext(AppContext)

  const [currRows, setCurrRows] = useState(rows)
  const [isFocused, setIsFocused] = useState(false)
  const hasText = !!value.trim()

  const handleFocus = (...args) => {
    setIsFocused(true)
    onFocus(...args)
    setCurrRows(focusRows || rows)
  }

  const handleBlur = (...args) => {
    setIsFocused(false)
    onBlur(...args)
    if (!hasText) {
      setCurrRows(rows)
    }
  }

  return (
    <Container
      {...restProps}
      isFocused={isFocused}
      largeText={!isWideMedia}
      withStyles={withStyles}
      fontSize={fontSize}
      padding={padding}
      color={color}
      isDarkBg={isDarkBg}
    >
      <TextareaAutoSize
        ref={ref}
        autoFocus={autoFocus}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        required={required}
        maxRows={maxRows}
        rows={currRows}
        value={value}
      />
    </Container>
  )
}

export default React.forwardRef(InputTextarea)

const Container = styled.div`
  position: relative;
  height: ${props => props.height ?? 'auto'};
  width: ${props => props.width ?? 'auto'};
  display: flex;
  align-items: flex-start;
  border: ${props => props.withStyles ?
    `solid 1px ${props.isFocused ? colors.grey : colors.greyMedium}` :
    0
  };
  background-color: ${props => props.withStyles ? 'rgba(255,255,255,0.75)' : null};
  border-radius: ${props => props.withStyles ? '0.25rem' : null};

  textarea {
    top: 0;
    left: 0;
    width: 100%;
    padding: ${props => props.padding};
    font-size: ${props => props.largeText ? 1.15 * props.fontSize : props.fontSize}px;
    color: ${props => props.color};
    position: relative;
    z-index: 2;
    min-height: 2.75rem;
    overscroll-behavior: contain;

    &::placeholder {
      color: ${props => props.isDarkBg ? colors.white : null};
      opacity: ${props => props.isDarkBg ? 0.7 : null};
    }
  }
`
