import styled from 'styled-components'

import colors from '../_consts/colors'
import Text from './Text'


const Section = ({ center, children, label, separator }) => (
  <Container center={center} separator={separator}>
    {label && (
      <Text block marginBottom='0.625rem' size='medium'>
        {label}
      </Text>
    )}
    {children}
  </Container>
)

export default Section

const Container = styled.div`
  position: relative;
  margin-bottom: 1rem;
  ${props => props.center && ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })}
  ${props => props.separator && ({
    borderTop: `solid 1px ${colors.greyMediumLight}`,
    paddingTop: '1rem',
  })}

  &:last-child {
    margin-bottom: 0;
  }
`
