import { useContext, useEffect, useRef, useState } from 'react'

import { AppContext } from '../AppProvider'
import useFontSizeToFit from './hooks/useFontSizeToFit'
import Renderer from './Renderer'
import useCounter from './hooks/useCounter'
import useIsMounted from '../_hooks/useIsMounted'
import { AnimationContext } from '../AnimationProvider'
import useDebounce from '../_hooks/useDebounce'
import Caret from './Caret'


const TerminalReader = () => {
  const { config, displayTextEl } = useContext(AppContext)
  const {
    setIsAnimating,
    setStartAnimation,
    isAnimating,
    speedFactor,
  } = useContext(AnimationContext)
  const isMountedRef = useIsMounted()
  const timeoutRef = useRef()
  const counter = useCounter()

  const { text } = config
  const trimmedText = text.trim()
  const shadowText = useDebounce(trimmedText)
  const { fontSize, text: displayText } = useFontSizeToFit(shadowText)
  const [displayIndex, setDisplayIndex] = useState(null)
  const [lastCharDims, setLastCharDims] = useState()

  useEffect(() => {
    setStartAnimation(() => {
      if (!displayText?.length || !isMountedRef.current || !displayTextEl) {
        return
      }
      setDisplayIndex(null)
      const firstCharEl = displayTextEl?.children.item(0)
      setLastCharDims(firstCharEl?.getBoundingClientRect())
      const totalChars = [...displayText].length

      counter({
        config: {
          startDelay: 1500,
          delay: 250 / speedFactor,
          variance: 0.65,
        },
        size: totalChars,
        onCount: (cnt) => {
          setDisplayIndex(cnt)
          const charEl = displayTextEl?.children.item(cnt < totalChars ? cnt : cnt - 1)
          setLastCharDims(charEl?.getBoundingClientRect())
        },
        onDone: () => {
          timeoutRef.current = window.setTimeout(() => {
            setIsAnimating(false)
          }, 2000)
        },
      })
    })

    return () => {
      window.clearTimeout(timeoutRef.current)
    }
  }, [displayText, speedFactor, displayTextEl])

  const chars = displayText ? [...displayText] : null
  const displayTextHtml = chars?.slice(0, displayIndex + 1).map((char, i) => (
    <span key={i} style={{ opacity: displayIndex === null ? 0 : null }}>
      {char}
    </span>
  ))

  return (
    <Renderer
      centered
      padding='1rem'
      shadowText={shadowText}
      displayText={displayTextHtml}
      displayTextStyles={{ fontSize, width: '100%' }}
    >
      {displayText && isAnimating && lastCharDims && fontSize && (
        <Caret
          color={config.hasStroke ? config.strokeColor : config.textColor}
          coords={{
            x: lastCharDims.x +
              (displayIndex === null ? (
                config.textAlign === 'center' ? lastCharDims.width / 2 :
                config.textAlign === 'right' ? lastCharDims.width :
                0
              ) : lastCharDims.width),
            y: lastCharDims.y,
          }}
          fontSize={fontSize}
        />
      )}
    </Renderer>
  )
}


export default TerminalReader
