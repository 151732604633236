import { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatAlignCenter,
  MdFormatAlignJustify,
} from 'react-icons/md'

import { AppContext } from './AppProvider'
import { Fonts } from './_consts/fonts'
import InputSwitch from './_components/InputSwitch'
import ToggleButton from './_components/ToggleButton'
import ColorPicker from './_components/ColorPicker'
import DropdownList from './_components/DropdownList'
import DropdownButton from './_components/DropdownButton'
import { AnimationNames, Animations, AnimationSpeeds } from './_consts/animations'


const EditStyle = () => {
  const { config, updateConfig, previousFont, setPreviousFont } = useContext(AppContext)

  const handleUpdate = (name, value) => {
    updateConfig({ [name]: value })
  }

  const handleUpdateAnimation = (value) => {
    handleUpdate('animation', value)

    if (value === Animations.shuffle) {
      // Nudge user to use monospace
      handleUpdate('font', 'courierNew')
      setPreviousFont(config.font)
    } else if (
      config.animation === Animations.shuffle &&
      config.font === 'courierNew' &&
      previousFont
    ) {
      // Try to return user to previous intended font (not very sophisticated - has holes)
      handleUpdate('font', previousFont)
    }
  }

  const fontsList = useMemo(() => {
    return Object.entries(Fonts)
  }, [])

  const animationsList = useMemo(() => {
    return Object.entries(AnimationNames)
  }, [])

  const animationSpeedsList = useMemo(() => {
    return Object.entries(AnimationSpeeds).sort((a, b) => a[0] < b[0] ? -1 : 1)
  }, [])

  const [isFontsListOpen, setIsFontsListOpen] = useState(false)
  const [isAnimationsListOpen, setIsAnimationsListOpen] = useState(false)
  const [isSpeedsListOpen, setIsSpeedsListOpen] = useState(false)
  const [lastAnimation, setLastAnimation] = useState(null)

  return (
    <>
      <Row>
        <SelectFontWrapper>
          <DropdownList
            isOpen={isFontsListOpen}
            onBlur={() => setIsFontsListOpen(false)}
            onSelect={(value) => {
              handleUpdate('font', value)
              setIsFontsListOpen(false)
            }}
            items={fontsList.map(([key, value]) => ({
              text: value,
              textStyles: { fontFamily: value },
              isChecked: config.font === key,
              value: key,
            }))}
          >
            <DropdownButton
              hasShading
              width='100%'
              textStyles={{ fontFamily: Fonts[config.font] }}
              text={Fonts[config.font]}
              onClick={() => setIsFontsListOpen(!isFontsListOpen)}
            />
          </DropdownList>
        </SelectFontWrapper>
        <ToggleButton
          name='textAlign'
          value='left'
          icon={MdFormatAlignLeft}
          isOn={config.textAlign === 'left'}
          onSelect={handleUpdate}
        />
        <ToggleButton
          name='textAlign'
          value='center'
          icon={MdFormatAlignCenter}
          isOn={config.textAlign === 'center'}
          onSelect={handleUpdate}
        />
        <ToggleButton
          name='textAlign'
          value='right'
          icon={MdFormatAlignRight}
          isOn={config.textAlign === 'right'}
          onSelect={handleUpdate}
        />
        <ToggleButton
          name='textAlign'
          value='justify'
          icon={MdFormatAlignJustify}
          isOn={config.textAlign === 'justify'}
          onSelect={handleUpdate}
        />
      </Row>
      <Row>
        <ToggleButton name='bold' isOn={config.bold} onToggle={handleUpdate}>
          <b>B</b>
        </ToggleButton>
        <ToggleButton name='italic' isOn={config.italic} onToggle={handleUpdate}>
          <i style={{ fontFamily: 'courier' }}>I</i>
        </ToggleButton>
        <ToggleButton name='overline' isOn={config.overline} onToggle={handleUpdate}>
          <span style={{ textDecoration: 'overline' }}>U</span>
        </ToggleButton>
        <ToggleButton name='lineThrough' isOn={config.lineThrough} onToggle={handleUpdate}>
          <span style={{ textDecoration: 'line-through' }}>S</span>
        </ToggleButton>
        <ToggleButton name='underline' isOn={config.underline} onToggle={handleUpdate}>
          <span style={{ textDecoration: 'underline' }}>U</span>
        </ToggleButton>
        <ToggleButton name='flipH' isOn={config.flipH} onToggle={handleUpdate}>
          <div style={{ transform: 'scaleX(-1)' }}>F</div>
        </ToggleButton>
        <ToggleButton name='flipV' isOn={config.flipV} onToggle={handleUpdate}>
          <div style={{ transform: 'scaleY(-1)', backfaceVisibility: 'hidden' }}>F</div>
        </ToggleButton>
      </Row>
      <Row>
        <InputSwitch
          name='breakWords'
          isOn={config.breakWords}
          onChange={handleUpdate}
          label='Break long words'
        />
      </Row>
      <Row>
        <ColorPicker
          name='bgColor'
          onChange={handleUpdate}
          value={config.bgColor}
          label='Background'
        />
      </Row>
      <Row>
        <Column>
          <ColorPicker
            name='textColor'
            onChange={handleUpdate}
            value={config.textColor}
            label='Text'
          />
        </Column>
        <Column>
          <InputSwitch
            name='hasStroke'
            isOn={config.hasStroke}
            onChange={handleUpdate}
            label='Stroke'
          />
          {config.hasStroke && (
            <ColorPicker
              name='strokeColor'
              onClick={() => handleUpdate('hasStroke', true)}
              onChange={handleUpdate}
              value={config.strokeColor}
            />
          )}
        </Column>
      </Row>
      <Row>
        <InputSwitch
          name='hasShadow'
          isOn={config.hasShadow}
          onChange={handleUpdate}
          label='Shadow'
        />
      </Row>
      <Row>
        <InputSwitch
          name='animation'
          isOn={!!config.animation}
          onChange={(name, isOn) => {
            if (!isOn) {
              setLastAnimation(config.animation)
            }
            handleUpdate(name, isOn ? lastAnimation || Animations.statesman : null)
          }}
          label='Animate'
        />
      </Row>
      {config.animation && (
        <Row>
          <DropdownList
            isOpen={isAnimationsListOpen}
            onBlur={() => setIsAnimationsListOpen(false)}
            onSelect={(value) => {
              handleUpdateAnimation(value)
              setIsAnimationsListOpen(false)
            }}
            items={animationsList.map(([key, value]) => ({
              text: value,
              isChecked: config.animation === key,
              value: key,
            }))}
          >
            <DropdownButton
              hasShading
              width='100%'
              text={AnimationNames[config.animation]}
              onClick={() => setIsAnimationsListOpen(!isAnimationsListOpen)}
            />
          </DropdownList>
          <DropdownList
            isOpen={isSpeedsListOpen}
            onBlur={() => setIsSpeedsListOpen(false)}
            onSelect={(value) => {
              handleUpdate('animationSpeed', value)
              setIsSpeedsListOpen(false)
            }}
            items={animationSpeedsList.map(([key, value]) => ({
              text: `× ${value}`,
              isChecked: config.animationSpeed === key,
              value: key,
            }))}
          >
            <DropdownButton
              hasShading
              width='100%'
              text={`× ${AnimationSpeeds[config.animationSpeed]}`}
              onClick={() => setIsSpeedsListOpen(!isSpeedsListOpen)}
            />
          </DropdownList>
        </Row>
      )}
    </>
  )
}

export default EditStyle

const SelectFontWrapper = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.spaceBetween ? 'space-between' : null};
  margin: 1.25rem 0;

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  > * {
    margin-left: 0.25rem;

    :first-child {
      margin-left: 0;
    }
  }
`

const Column = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;

  :first-child {
    margin-left: 0;
  }

  > * {
    margin-left: 0.5rem;

    :first-child {
      margin-left: 0;
    }
  }
`
