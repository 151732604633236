import { useContext, useEffect, useMemo } from 'react'
import { easings, useSpring } from '@react-spring/web'

import { AppContext } from '../AppProvider'
import { AnimationContext } from '../AnimationProvider'
import useFontSizeToFit from './hooks/useFontSizeToFit'
import Renderer from './Renderer'
import Stars from '../scenes/Stars'


const SpaceConflictsReader = () => {
  const { config, appDims } = useContext(AppContext)
  const { setStartAnimation, setIsAnimating, speedFactor } = useContext(AnimationContext)
  const shadowText = config.text.trim()
  const { fontSize, text: displayText } = useFontSizeToFit(
    shadowText,
    { minSize: 30, maxSize: appDims.width * 0.25 },
  )

  const animProps = useMemo(() => ({
    config: {
      easing: easings.linear,
      duration: 20000 / speedFactor,
    },
    from: {
      transform:
        `rotateX(35deg) translate3d(0, ${appDims.height * 1.1}px, -${appDims.height / 10}px)`,
      x: 0,
    },
    to: {
      transform:
        `rotateX(35deg) translate3d(0, ${appDims.height * -3}px, -${appDims.height / 10}px))`,
      x: 1,
    },
  }), [appDims.height, speedFactor])

  const [animStyles, api] = useSpring(() => ({
    ...animProps,
    onRest: () => {
      setIsAnimating(false)
    },
  }))

  const opacity = animStyles.x.to({
    range: [0, 0.9, 1],
    output: [1, 1, 0],
  })

  useEffect(() => {
    if (api) {
      setStartAnimation(() => {
        api.start(animProps)
      })
    }
  }, [api, animProps])

  return (
    <Renderer
      perspective={225}
      shadowText={shadowText}
      displayText={displayText}
      displayTextStyles={{
        ...animStyles,
        fontSize,
        transformStyle: 'preserve-3d',
        opacity,
        minHeight: '100%',
        width: '100%',
      }}
      backScene={<Stars />}
    />
  )
}


export default SpaceConflictsReader
