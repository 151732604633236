import { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { MdReplay } from 'react-icons/md'

import { ReactComponent as BirdyIcon } from './_assets/birdy-plus.svg'
import colors from './_consts/colors'
import { AppContext } from './AppProvider'
import { AnimationContext } from './AnimationProvider'
import PlainReader from './readers/PlainReader'
import StatesmanReader from './readers/StatesmanReader'
import { Animations } from './_consts/animations'
import SpaceConflictsReader from './readers/SpaceConflictsReader'
import TerminalReader from './readers/TerminalReader'
import ShuffleReader from './readers/ShuffleReader'
import WhisperReader from './readers/WhisperReader'
import Impressions from './_components/Impressions'
import JumpingReader from './readers/JumpingReader'
import DropReader from './readers/DropReader'
import ZippyReader from './readers/ZippyReader'


const ViewItem = ({
  children,
  top,
  left,
  bottom,
  right,
  padding,
  iconSize,
  hasBackground,
  onClick,
}) => {
  const { isDarkBg } = useContext(AppContext)

  return (
    <ViewItemContainer
      as={onClick ? 'button' : 'div'}
      isDarkBg={isDarkBg}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      padding={padding}
      iconSize={iconSize}
      hasBackground={hasBackground}
      onClick={onClick}
    >
      {children}
    </ViewItemContainer>
  )
}

const ReadView = ({ onPointerUp }) => {
  const { config, createNew, isEditMode, isPreviewing, linkId } = useContext(AppContext)
  const { isAnimating, canAnimate, startAnimation } = useContext(AnimationContext)
  const [canShowControls, setCanShowControls] = useState(isEditMode)
  const timeoutIdRef = useRef()
  const hasShownControlsRef = useRef(false)
  const { animation } = config

  useEffect(() => {
    window.clearTimeout(timeoutIdRef.current)

    if (canAnimate && isAnimating && (!isEditMode || !isPreviewing)) {
      setCanShowControls(false)
    } else {
      timeoutIdRef.current = window.setTimeout(() => {
        setCanShowControls(true)
        hasShownControlsRef.current = true
      }, hasShownControlsRef.current ? 1000 : 3000)
    }

    return () => {
      window.clearTimeout(timeoutIdRef.current)
    }
  }, [isAnimating])

  return (
    <>
      <Container onPointerUp={onPointerUp}>
        {!animation && <PlainReader />}
        {animation === Animations.statesman && <StatesmanReader />}
        {animation === Animations.drop && <DropReader />}
        {animation === Animations.terminal && <TerminalReader />}
        {animation === Animations.spaceConflicts && <SpaceConflictsReader />}
        {animation === Animations.shuffle && <ShuffleReader />}
        {animation === Animations.whisper && <WhisperReader />}
        {animation === Animations.jumping && <JumpingReader />}
        {animation === Animations.zippy && <ZippyReader />}
      </Container>
      {canShowControls && (
        <>
          {!isEditMode && !isAnimating && (
            <ViewItem
              left
              top
              onClick={createNew}
              iconSize={1.75}
              hasBackground
            >
              <BirdyIcon />
            </ViewItem>
          )}
          {isEditMode && !isPreviewing && !!linkId && (
            <ViewItem
              bottom
              left
              padding='0.25rem 0.5rem'
              hasBackground
            >
              <Impressions />
            </ViewItem>
          )}
          {canAnimate && !isAnimating && (
            <ViewItem
              bottom
              right
              onClick={startAnimation}
              iconSize={1.75}
              hasBackground
            >
              <MdReplay />
            </ViewItem>
          )}
        </>
      )}
    </>
  )
}

const ReadViewWrapper = (props) => {
  const { isFontLoaded } = useContext(AppContext)

  if (isFontLoaded) {
    return <ReadView {...props} />
  }

  return null
}

export default ReadViewWrapper

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const ViewItemContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 3;
  ${props => props.hasBackground && {
    backgroundColor: 'rgba(230,230,230,0.5)',
    borderRadius: '10rem',
  }}
  padding: ${props => props.padding ?? '0.5rem'};
  top: ${props => props.top ? 0 : null};
  left: ${props => props.left ? 0 : null};
  bottom: ${props => props.bottom ? 0 : null};
  right: ${props => props.right ? 0 : null};
  margin: 0.75rem;
  color: ${props => props.isDarkBg ? colors.white : colors.blackish};

  svg {
    opacity: 0.9;
    width: ${props => props.iconSize || 1.25}rem;
    height: ${props => props.iconSize || 1.25}rem;
  }
`
