import { useContext, useEffect, useRef, useState } from 'react'
import { animated, config as sconfig, useTransition } from '@react-spring/web'

import { AppContext } from '../AppProvider'
import useFontSizeToFit from './hooks/useFontSizeToFit'
import Renderer from './Renderer'
import useCounter from './hooks/useCounter'
import useIsMounted from '../_hooks/useIsMounted'
import { AnimationContext } from '../AnimationProvider'
import useDebounce from '../_hooks/useDebounce'
import useShadowCharDims from './hooks/useShadowCharDims'


const ZippyReader = () => {
  const { appDims, config } = useContext(AppContext)
  const {
    setIsAnimating,
    setStartAnimation,
    speedFactor,
  } = useContext(AnimationContext)
  const isMountedRef = useIsMounted()
  const counter = useCounter()
  const shadowText = useDebounce(config.text.trim())
  const { fontSize, text: displayText } = useFontSizeToFit(shadowText)
  const wordDims = useShadowCharDims(displayText)
  const [enterCount, setEnterCount] = useState(null)
  const enterCountRef = useRef()

  useEffect(() => {
    setStartAnimation(() => {
      if (!wordDims?.length || !isMountedRef.current) {
        return
      }

      setIsAnimating(true)
      setEnterCount(0)

      counter({
        config: {
          delay: 500 / speedFactor,
        },
        size: wordDims?.length,
        onCount: (cnt) => {
          setEnterCount(cnt)
          enterCountRef.current = cnt
        },
      })
    })
  }, [speedFactor, wordDims])

  const transitions = useTransition(wordDims?.slice(0, enterCount), {
    config: {
      ...sconfig.stiff,
      tension: 160,
      bounce: 1,
    },
    from: { translateX: `${appDims.width}px`, skew: '30deg' },
    enter: { translateX: '0px', skew: '0deg' },
    onRest: () => {
      if (enterCountRef.current === wordDims.length) {
        window.requestAnimationFrame(() => {
          setIsAnimating(false)
        })
      }
    },
  })

  const displayTextHtml = wordDims ?
    transitions((props, item) => (
      <animated.div
        key={item.index}
        style={{
          ...props,
          position: 'fixed',
          left: wordDims[item.index].dims.left,
          top: wordDims[item.index].dims.top,
        }}
      >
        {item.char}
      </animated.div>
    )) : null

  return (
    <Renderer
      centered
      shadowText={shadowText}
      wrapShadowWords
      displayText={displayTextHtml}
      displayTextStyles={{ fontSize }}
    />
  )
}


export default ZippyReader
