import styled from 'styled-components'

import Copyright from './_components/Copyright'
import Logo from './_components/Logo'
import Text from './_components/Text'
import colors from './_consts/colors'


const About = () => {
  return (
    <Container>
      <Logo color={colors.accent} to='/' />
      <div>
        <Text>
          Lovingly created by Kane Elfman. You can reach him at: <b>hello@wordydoo.com</b>
        </Text>
      </div>
      <div>
        <Copyright />
      </div>
    </Container>
  )
}

export default About

const Container = styled.div`
  padding: 2rem;
  height: 100%;
  background-color: #fff4f4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
