import { animated, easings, useSpring } from '@react-spring/web'
import React, { useState } from 'react'
import styled from 'styled-components'

import colors from '../_consts/colors'
import Text from './Text'


const InputSwitch = ({
  disabled,
  name,
  isOn,
  label,
  onChange,
  ...restProps
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleStyles = useSpring({
    transform: `translateX(${isOn ? 0.875 : 0}rem`,
    config: {
      duration: 150,
      easing: easings.easeOut,
    },
  })

  return (
    <Container disabled={disabled} {...restProps}>
      <Switch isFocused={isFocused} isOn={isOn}>
        {!disabled && (
          <input
            type='checkbox'
            checked={isOn}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            onChange={() => onChange(name, !isOn)}
          />
        )}
        <Handle style={handleStyles} />
      </Switch>
      <Text>{label}</Text>
    </Container>
  )
}

export default InputSwitch

const Container = styled.label`
  display: flex;
  align-items: center;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
`

const Switch = styled.div`
  position: relative;
  height: 1.5rem;
  width: 2.375rem;
  border-radius: 1rem;
  margin-right: 0.5rem;
  background-color: ${props => props.isOn ? colors.accent : colors.greyMedium};

  input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: inherit;
  }
`

const Handle = styled(animated.div)`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 1rem;
  background-color: ${colors.white};
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0,0,0,0.5);
`
